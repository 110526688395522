import React, {Component} from 'react';
import {Link} from "react-router-dom";
import "../../../node_modules/slick-carousel/slick/slick.css";
import FontAwesome from "../uiStyle/FontAwesome";
import Button from "react-bootstrap/Button";
import { css, cx } from '@emotion/css';

// images
import gsil1 from '../../doc/img/blog/post_gsi1.jpg';
import gsil2 from '../../doc/img/blog/post_gsi2.jpg';
import gsil3 from '../../doc/img/blog/post_gsi3.jpg';
import gsil4 from '../../doc/img/blog/post_gsi4.jpg';
import gsil5 from '../../doc/img/blog/post_gsi5.jpg';
import gsil6 from '../../doc/img/blog/post_gsi6.jpg';
import gsil7 from '../../doc/img/blog/post_gsi7.jpg';
import sliderImg1 from '../../doc/img/header/sider-top.jpg';
import sliderImg2 from '../../doc/img/header/sider-top2.jpg';
import guySmokingImg from '../../doc/img/important_images/guy-smoking.jpg';
import spookyEyesBackground from '../../new_images/spooky-eyes-background.jpg';
import StrangestFictionPoster from '../../new_images/branding/StrangestFictionFinalVersion.png';
import SkinnySpookyEyes from '../../new_images/branding/skinny-spooky-eyes.jpg';
import volumeOneImage from '../../new_images/publications/AnthologyCover.jpg';

import TopStories from '../TopStories';

import './style.scss';
import '../primary-styles.css';

function SampleNextArrow(props) {
    const {className, onClick} = props;
    return (
        <div onClick={onClick} className={`${className} slider_arrow arrow_right slick-arrow`}>
            <FontAwesome name="angle-right"/>
        </div>
    );
}

function SamplePrevArrow(props) {
    const {className, onClick} = props;
    return (
        <div onClick={onClick} className={`${className} slider_arrow arrow_left slick-arrow`}>
            <FontAwesome name="angle-left"/>
        </div>
    );
}

class PostGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
            vModal: false,
            videoId: '0r6C3z3TEKw'
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    modalHandler = (value) => {
        this.setState({
            vModal: value
        })
    };

    render() {
        const {className} = this.props;
        
        return (
            <div className={`post_gallary_area mb40 ${className}`}>
                
                {/* <div className="container">
                    <div className="image-quote-container">
                        <img src={spookyEyesBackground} alt="Guy smoking in the dark" className="home-background-image"/>
                        <div className="website-title-overlay">
                            <div className="website-title">
                                Strangest Fiction
                            </div>
                        </div>
                        <div className="top-stories-overlay"><TopStories history={this.props.history} allStories={this.props.allStories} allUsers={this.props.allUsers}/></div>
                    </div>
                </div> */}

                {/* <div className={css`padding: 10px; background-image: url("${SkinnySpookyEyes}")`}>
                    <div className={css`display: flex; justify-content: center `}>
                        <div className={css`padding-bottom: 15px; font-size: 25px; font-weight: bold;`}>
                            GET PUBLISHED TODAY!
                        </div>
                    </div>
                    <div className={css`display: flex;  justify-content: center;`}>
                        <div className={css`max-width: 225px;`}>
                            <div className={css`font-size: 18px; line-height: 1.2; padding-bottom: 10px;`}>
                                We are looking for writers to be published in our Strangest Fiction Anthology coming this fall!!
                            </div>
                            <Link to="/announcements/anthology" className={css`width: 100%;`}>
                                <Button variant="primary" className="email-signup-button" onClick={this.submitClicked}>
                                    Learn More
                                </Button>
                            </Link>
                        </div>
                        <img src={StrangestFictionPoster} alt="temp icon" className={css`
                            width: 150px;
                            height: 150px;
                            margin-left: 10px;
                        `}/>
                    </div>
                </div> */}

                <div className={css`padding: 10px; background-image: url("${SkinnySpookyEyes}")`}>
                    <div className={css`display: flex; justify-content: center `}>
                        <div className={css`padding-bottom: 15px; font-size: 25px; font-weight: bold;`}>
                            SF ANTHOLOGY NOW AVAILABLE!
                        </div>
                    </div>
                    <div className={css`display: flex;  justify-content: center;`}>
                        <div className={css`max-width: 250px;`}>
                            <div className={css`font-size: 18px; line-height: 1.2; padding-bottom: 10px;`}>
                                We are beyond thrilled to announce that our first short story collection is now available for purchase in eBook and Softcover! Click below to secure your copy today.
                            </div>
                            <Link to="/publications/anthology-vol-1" className={css`width: 100%;`}>
                                <Button variant="primary" className="email-signup-button">
                                    Learn More
                                </Button>
                            </Link>
                        </div>
                        <img src={volumeOneImage} alt="Anthology Volume 1 Image" className={css`
                            width: 150px;
                            margin-left: 15px;
                        `}/>
                    </div>
                </div>

                <div className={css`display: flex; justify-content: center; padding-top: 50px; padding-bottom: 10px;`}>
                    <div className={css`max-width: 500px; text-align: center;`}>
                        <h2>GREETINGS</h2>
                        <div className={css`font-size: 25px; padding-top: 10px;`}>
                            Strangest Fiction is a writing platform for supernatural, horror, science fiction and thriller authors. Post your writing without any restrictions and gain exposure from our community. Top performing stories will be featured in upcoming publications!
                        </div>
                    </div>
                </div>
            
            </div>
        );
    }
}

export default PostGallery;