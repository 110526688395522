import {IS_LOADING, RE_RENDERED, THROW_ERROR, THROW_SUCCESS} from "../constants";
import {toast} from "react-toastify";

const init = {
    allStories: null,
    allUsers: null,
    allPrompts: null,
    allSubmissions: null,
    isLoading: false,
    emailVerified: true,
    currentUser: null,
    checkForUser: false,
    loginModals: {
        showLoginModal: false,
        showCreateAccount: true,
        showSuccess: false,
        showPassReset: false,
        newUsername: null
    },
    otherModals: {
        showEmailVerifiedModal: false,
        showPromptCreateAccountModal: false,
        showExitPageModal: false,
        showNotificationsModal: false
    },
    savedStoryInfo: null,
    submissionDetails: null,
};

const metaReducer = (state = init, action) => {
    switch (action.type) {
        case "GRAB_ALL_STORIES": {
            return {
                ...state,
                allStories: action.payload,
                isLoading: false
            }
        }
        case "GRAB_INITIAL_DATA": {
            return {
                ...state,
                allStories: action.payload.allStories,
                allUsers: action.payload.allUsers,
                allPrompts: action.payload.allPrompts,
                allCompetitions: action.payload.allCompetitions,
                isLoading: false
            }
        }
        case "GRAB_ALL_SUBMISSIONS": {
            console.log(action.payload);
            return {
                ...state,
                allSubmissions: action.payload,
                isLoading: false
            }
        }
        case "SET_LOADING_STATE": {
            return {
                ...state,
                isLoading: action.payload
            }
        }
        case "SET_EMAIL_VERIFIED": {
            return {
                ...state,
                emailVerified: action.payload
            }
        }
        case "SET_CURRENT_USER": {
            return {
                ...state,
                currentUser: action.payload.currentUser,
                checkForUser: action.payload.checkForUser
            }
        }
        case "SET_LOGIN_MODALS": {
            return {
                ...state,
                loginModals: action.payload
            }
        }
        case "SET_PROMPT_CREATE_ACCOUNT_MODAL": {
            return {
                ...state,
                ...state.otherModals,
                showPromptCreateAccountModal: action.payload
            }
        }
        case "SET_NOTIFICATIONS_MODAL": {
            return {
                ...state,
                ...state.otherModals,
                showNotificationsModal: action.payload
            }
        }
        case "SET_SAVED_STORY": {
            return {
                ...state,
                savedStoryInfo: action.payload
            }
        }
        case "SET_SUBMISSION_DETAILS": {
            return {
                ...state,
                submissionDetails: action.payload
            }
        }
        default:
            return state;
    }
};

export default metaReducer;