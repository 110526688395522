import React, {Fragment} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import BlockedErrorModal from '../modals/BlockedErrorModal';

import './WriteStoryPage.css';

import blankPicture from '../new_images/blank-picture.png';
import { getDBRef, getStorageRef } from '../FirebaseActions';

export default class EditStoryModal extends React.Component {
    constructor(props) {
        super(props);

        this.imgInput = React.createRef();
        this.promptSelector = React.createRef();

        this.state = {       
            storyTitle: "",
            storyDescription: "",
            storyText: "",
            scifiTag: "",
            superTag: "",
            horrorTag: "",
            fantasyTag: "",
            thrillerTag: "",
            histFicTag: "",
            showPublishModal: false,
            showSavedModal: false,
            allStoryInformation: null,
            imgURL: this.props.savedInfo.allStoryInformation.imgURL,
            showLoading: false,
            showBlockedModal: false,
            selectedPromptId: null,
            selectedPromptTitle: null,
            showPrompts: false
        };

        this.imageUploaded = this.imageUploaded.bind(this);
        this.promptChanged = this.promptChanged.bind(this);
    }

    tagClicked(tagName) {
        if (tagName == "scifi") {
            if (this.state.scifiTag == "") {
                this.setState({scifiTag: "tag-highlighted"});
            } else { this.setState({scifiTag: ""})}
        } else if (tagName == "super") {
            if (this.state.superTag == "") {
                this.setState({superTag: "tag-highlighted"});
            } else { this.setState({superTag: ""})}
        } else if (tagName == "horror") {
            if (this.state.horrorTag == "") {
                this.setState({horrorTag: "tag-highlighted"});
            } else { this.setState({horrorTag: ""})}
        } else if (tagName=="fantasy") {
            if (this.state.fantasyTag == "") {
                this.setState({fantasyTag: "tag-highlighted"})
            } else { this.setState({fantasyTag: ""})}
        } else if (tagName == "thriller") {
            if (this.state.thrillerTag == "") {
                this.setState({thrillerTag: "tag-highlighted"});
            } else { this.setState({thrillerTag: ""})}
        } else if (tagName == "histfic") {
            if (this.state.histFicTag =="") {
                this.setState({histFicTag: "tag-highlighted"});
            } else { this.setState({histFicTag: ""})}
        }
      }

      publishClicked() {
        if (this.state.storyTitle != "") {
            if (this.state.storyDescription != "") {
                if (document.getElementById("story-text").innerHTML.length > 200) {
                    if (this.props.currentUser.blocked == null) {
                        this.publishStory();
                    } else {
                        this.setState({showBlockedModal: true});
                    }
                } else {
                    this.textError.current.style.display="block";
                }
            } else {
                this.descriptionError.current.style.display="block";
            }
        } else {
            this.titleError.current.style.display="block";
        }
      }

      publishStory() {
          //console.log(document.getElementById("story-text").innerHTML);
          let db = getDBRef();

          let tags = [];
          if (this.state.scifiTag != "") { tags.push('Science Fiction'); }
          if (this.state.superTag != "") { tags.push('Supernatural'); }
          if (this.state.horrorTag != "") { tags.push('Horror'); }
          if (this.state.fantasyTag != "") { tags.push('Fantasy'); }
          if (this.state.thrillerTag != "") { tags.push('Thriller'); }
          if (this.state.histFicTag != "") { tags.push('Historical Fiction'); }

          let anonSlider = document.getElementById('anon-slider').checked;
          let matureSlider = document.getElementById('mature-content-slider').checked;
          let publicationSlider = document.getElementById('publication-slider').checked;

          let favorites = [];
          if (this.props.savedInfo.allStoryInformation.favorites != null) {
              favorites = this.props.savedInfo.allStoryInformation.favorites;
          }

          let allStoryInformation = {
                title: this.state.storyTitle,
                tags: tags,
                description: this.state.storyDescription,
                favorites: favorites,
                publishAnon: anonSlider,
                matureContent: matureSlider,
                publicationSlider: publicationSlider,
                storyText: document.getElementById("story-text").innerHTML,
                username: this.props.currentUser.username,
                fullname: this.props.currentUser.fullname,
                viewcount: this.props.savedInfo.allStoryInformation.viewcount,
                thumbsUp: this.props.savedInfo.allStoryInformation.thumbsUp,
                thumbsDownCount: this.props.savedInfo.allStoryInformation.thumbsDownCount,
                commentsCount: this.props.savedInfo.allStoryInformation.commentsCount,
                shares: this.props.savedInfo.allStoryInformation.shares,
                imgURL: this.state.imgURL,
                promptId: this.state.selectedPromptId
          }

          // Checks the case where a user did upload a story image
          if (this.state.imgURL != null && this.imgInput.current.files[0] != null) {
            //console.log(this.imgInput.current.files[0]);

            // Create a root reference
            var storageRef = getStorageRef();

            // Create a reference to 'images/mountains.jpg'
            var newImageRef = storageRef.child('story-images/' + this.state.storyTitle + '/story_img.jpg');

            // 'file' comes from the Blob or File API
            this.setState({showLoading: true});
            newImageRef.put(this.imgInput.current.files[0]).then((snapshot) => {
                snapshot.ref.getDownloadURL().then((downloadURL) => {
                    //console.log(downloadURL);
                    allStoryInformation.imgURL = downloadURL;
                    
                    setTimeout(() => {
                        let editStoryRef = db.collection("users").doc(this.props.currentUser.uid).collection("stories").doc(this.props.savedInfo.id);
                        editStoryRef.set({
                            allStoryInformation: allStoryInformation,
                            id: this.props.savedInfo.id
                        })
            
                        db.collection("all-stories").doc(editStoryRef.id).update({
                            allStoryInformation: allStoryInformation,
                            id: this.props.savedInfo.id,
                            creatorID: this.props.currentUser.uid,
                            profileImg: this.props.currentUser.imgUrl
                        })

                        if (this.state.selectedPromptId != null) {
                            let newStoryIds = this.props.allPrompts.find(element => element.id == this.state.selectedPromptId).storyIds;
                            newStoryIds.push(this.props.savedInfo.id);
                            db.collection("prompts").doc(this.state.selectedPromptId).update({
                                storyIds: newStoryIds
                            })
                        }

                        this.setState({showLoading: false});
                        this.props.closeEditModalStoryEdited();
                    }, 500);
        
                })
            });
          }
          // Checks case where user did not upload a story image 
          else {
            let editStoryRef = db.collection("users").doc(this.props.currentUser.uid).collection("stories").doc(this.props.savedInfo.id);
            editStoryRef.set({
                allStoryInformation: allStoryInformation,
                id: this.props.savedInfo.id
            })

            db.collection("all-stories").doc(editStoryRef.id).update({
                allStoryInformation: allStoryInformation,
                id: this.props.savedInfo.id,
                creatorID: this.props.currentUser.uid,
                profileImg: this.props.currentUser.imgUrl
            })

            if (this.state.selectedPromptId != null) {
                let newStoryIds = this.props.allPrompts.find(element => element.id == this.state.selectedPromptId).storyIds;
                newStoryIds.push(this.props.savedInfo.id);
                db.collection("prompts").doc(this.state.selectedPromptId).update({
                    storyIds: newStoryIds
                })
            }

            this.props.closeEditModalStoryEdited();
          }
      }

      closePublishModal() {
          this.setState({showPublishModal: false})
      }

    imageUploaded() {
        const [file] = this.imgInput.current.files;
        if (file) {
            this.setState({imgURL: URL.createObjectURL(file) });
        }
    }

    preLoadPrompt(promptId) {
        let promptInfo = this.props.allPrompts.find(element => element.id == promptId);
        this.setState({selectedPromptId: promptId, selectedPromptTitle: promptInfo.title});
        //console.log(promptId);
    }

    promptChanged() {
        let promptInfo = this.props.allPrompts.find(element => element.id == this.promptSelector.current.value);
        //console.log(promptInfo)
        this.setState({selectedPromptId: promptInfo.id, selectedPromptTitle: promptInfo.title, showPrompts: false});
    }

    componentDidMount() {
        document.getElementById('up_btn').style.display = 'none';
        //console.log(this.props.savedInfo);
        if (this.props.savedInfo != null) {

            this.setState(() => { return {storyTitle: this.props.savedInfo.allStoryInformation.title, storyDescription: this.props.savedInfo.allStoryInformation.description}})

            document.getElementById('story-text').innerHTML = this.props.savedInfo.allStoryInformation.storyText;
            document.getElementById('anon-slider').checked = this.props.savedInfo.allStoryInformation.publishAnon;
            document.getElementById('mature-content-slider').checked = this.props.savedInfo.allStoryInformation.matureContent;

            for (let i = 0; i < this.props.savedInfo.allStoryInformation.tags.length; i++) {
                if (this.props.savedInfo.allStoryInformation.tags[i] == 'Science Fiction') { this.setState({scifiTag: "tag-highlighted"}); }
                if (this.props.savedInfo.allStoryInformation.tags[i] == 'Supernatural') { this.setState({superTag: "tag-highlighted"}); }
                if (this.props.savedInfo.allStoryInformation.tags[i] == 'Horror') { this.setState({horrorTag: "tag-highlighted"}); }
                if (this.props.savedInfo.allStoryInformation.tags[i] == 'Fantasy') { this.setState({fantasyTag: "tag-highlighted"}); }
                if (this.props.savedInfo.allStoryInformation.tags[i] == 'Thriller') { this.setState({thrillerTag: "tag-highlighted"}); }
                if (this.props.savedInfo.allStoryInformation.tags[i] == 'Historical Fiction') { this.setState({histFicTag: "tag-highlighted"}); }
            }
        }
    }

    render() {
        return ( 
            <Modal show={this.props.showModal} onHide={this.props.closeModal} size="lg">
                <Modal.Header className="background-grey-edit" closeButton>
                    <div className="center-content">
                        <h3>
                            Edit Story
                        </h3>
                    </div>
                </Modal.Header>
                <Modal.Body className="background-grey-edit">
                <div className="row">
                        <div className="col-12 col-lg-10 m-auto">
                            <BlockedErrorModal showModal={this.state.showBlockedModal} closeModal={() => {this.setState({showBlockedModal: false})}}/>

                            <div className="story-title-container">
                                <div className="story-title">
                                    Title
                                </div>
                                <input type="text" value={this.state.storyTitle} onChange={(e) => {this.setState({storyTitle: e.target.value})}} id="story-title-input" className="story-title-input" autofocus/>
                                <div className="error-message" ref={this.titleError}>
                                    Your story must have a title!
                                </div>
                            </div>
                        
                            <div className="story-title-container">
                                <div className="story-title">
                                    Tags
                                </div>
                                <div className="tag-options">
                                    <div className={"tag-option " + this.state.scifiTag} onClick={() =>  {this.tagClicked("scifi")}}>Science Fiction</div>
                                    <div className={"tag-option " + this.state.superTag} onClick={() =>  {this.tagClicked("super")}}>Supernatural</div>
                                    <div className={"tag-option " + this.state.horrorTag} onClick={() =>  {this.tagClicked("horror")}}>Horror</div>
                                    <div className={"tag-option " + this.state.fantasyTag} onClick={() =>  {this.tagClicked("fantasy")}}>Fantasy</div>
                                    <div className={"tag-option " + this.state.thrillerTag} onClick={() =>  {this.tagClicked("thriller")}}>Thriller</div>
                                    <div className={"tag-option " + this.state.histFicTag} onClick={() =>  {this.tagClicked("histfic")}}>Historical Fiction</div>
                                </div>
                            </div>

                            <div className="story-title-container">
                                <div className="story-title">
                                    Prompt
                                </div>
                                {this.state.selectedPromptId != null && !this.state.showPrompts && (
                                    <div className='write-story-prompt-info'>
                                        <i>{this.state.selectedPromptTitle}</i>
                                        <a className="edit-prompt-link" onClick={() => {this.setState({showPrompts: true})}}>Edit</a> - <a className="remove-prompt-link" onClick={() => {this.setState({selectedPromptId: null, selectedPromptTitle: null})}}>Remove</a>
                                    </div>
                                )}
                                {this.state.selectedPromptId == null && !this.state.showPrompts && (
                                    <div className="no-prompt-selected">No prompt selected for this story. Does this story satisfy one of our prompts? <a className="edit-prompt-link" onClick={() => {this.setState({showPrompts: true})}}>Click here.</a></div>
                                )}
                                {this.state.showPrompts && (
                                    <>
                                        <select name="options" className="prompt-selector" id="sort-selector" onChange={this.promptChanged} ref={this.promptSelector}>
                                            {this.props.allPrompts != null && this.props.allPrompts.map((prompt) => {
                                                return <option value={prompt.id}>{prompt.title}</option>
                                            })}
                                        </select>
                                        <a className="remove-prompt-link" onClick={() => {this.setState({selectedPromptId: null, selectedPromptTitle: null, showPrompts: false})}}>Remove</a>
                                    </>
                                )}
                            </div>

                            <div className="story-title-container">
                                <div className="story-title">
                                    Description
                                </div>
                                <div className="story-page-message">
                                    Max of 250 Characters
                                </div>
                                <textarea type="text" maxlength="250" rows="3" value={this.state.storyDescription} onChange={(e) => {this.setState({storyDescription: e.target.value})}} id="story-title-input" className="story-description-input" autofocus/>
                                <div className="error-message" ref={this.descriptionError}>
                                    Your story must have a description!
                                </div>
                            </div>

                            <div className="story-title-container">
                                <div className="story-title">
                                    Image Upload
                                </div>

                                {this.state.imgURL != null && (
                                    <img src={this.state.imgURL} alt="profile-image" className="uploaded-image"/>
                                )}
                                {this.state.imgURL == null && (
                                    <img src={blankPicture} alt="profile-image" className="uploaded-image"/>
                                )}

                                <Form className="new-image-input">
                                    <Form.Group>
                                        <Form.File id="exampleFormControlFile1" onChange={this.imageUploaded} ref={this.imgInput} />
                                    </Form.Group>
                                </Form>
                            </div>

                            <div className="story-title-container">
                                <div className="story-title">
                                    Options
                                </div>
                                <div className="make-name-public">
                                    <div className="story-question-text">
                                        Publish the story anonymously? (Name and image hidden)
                                    </div>
                                    <label class="switch">
                                        <input type="checkbox" id="anon-slider"/>
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <div className="make-name-public">
                                    <div className="story-question-text">
                                        Does your story contain mature content?
                                    </div>
                                    <label class="switch">
                                        <input type="checkbox" id="mature-content-slider"/>
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <div className="make-name-public">
                                    <div className="story-question-text">
                                        Would you like your story to be considered for inclusion in upcoming publications?
                                    </div>
                                    <label class="switch">
                                        <input type="checkbox" id="publication-slider" checked="true"/>
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>

                            <div className="story-title-container">
                                <div className="story-title">
                                    Story Text
                                </div>
                                <div className="story-page-message">
                                    Write to your heart's content!
                                </div>
                                <div contenteditable="true" id="story-text" value={this.state.storyText} onChange={(e) => {this.setState({storyText: e.target.value})}} className="story-text-input">
                                    Edit me
                                </div>
                                <div className="error-message" ref={this.textError}>
                                    Your story must have at least 250 characters!
                                </div>
                            </div>

                            <div className="submit-buttons-row">
                                <Button variant="primary" className="button-shadow" size="lg" onClick={() => {this.publishClicked()}}>Save Changes</Button>
                            </div>

                            {this.state.showLoading && (
                                <Fragment>
                                    <div class="d-flex justify-content-center">
                                        <Spinner animation="border" variant="primary" className="loading-spinner">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </div>
                                </Fragment>
                            )}

                            <div className="space-20"/>
                        </div>
                        
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

}