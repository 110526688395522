import { createStore } from 'redux';

let initialState = {
    testVal: 'here is a test for ya'
}

const testReducer = function (state = initialState, action) {
    switch (action.type) {
      case "SET_VAL":
        return {
            ...state,
            testVal: action.payload
        }
      default:
        return state;
    }
};

export default testReducer;