import React, {useState, useRef} from 'react';
import Heading from "./uiStyle/Heading";
import Swiper from 'react-id-swiper';

import './CategoriesCarousel.css';

import union from '../doc/img/icon/union.png';

import fnewsImg2 from '../doc/img/feature/feature2.jpg';
import fnewsImg3 from '../doc/img/feature/feature3.jpg';
import fnewsImg4 from '../doc/img/feature/feature4.jpg';
import {Link} from "react-router-dom";
import FontAwesome from "./uiStyle/FontAwesome";

// import theKeep from '../new_images/the_keep.jpg';
// import theTomb from '../new_images/the_tomb.jpg';
// import invisibleMan from '../new_images/invisible_man.jpg';
// import timeMachine from '../new_images/time_machine.jpg';
// import unsolicitedImage from '../new_images/unsolicited.jpg';

import fantasyImage from '../new_images/carousel-images/fantasy-carousel-image.jpg';
import histficImage from '../new_images/carousel-images/histfic-carousel-image.jpg';
import horrorImage from '../new_images/carousel-images/horror-carousel-image.jpg';
import scifiImage from '../new_images/carousel-images/scifi-carousel-image.jpg';
import supernaturalImage from '../new_images/carousel-images/supernatural-carousel-image.jpg';
import thrillerImage from '../new_images/carousel-images/thriller-carousel-image.jpg';

import volumeOneImage from '../new_images/publications/AnthologyCover.jpg';
import volumeTwoImage from '../new_images/publications/AnthologyCoverV2.jpg';
import StrangestFictionPoster from '../new_images/branding/StrangestFictionFinalVersion.png';
import SFMug from '../new_images/sf-mug.jpeg';

import histFicBanner from '../new_images/histfic-banner.jpg';

const news = [
    {
        image: volumeTwoImage,
        category: 'Anthology V2',
        link: "https://a.co/d/c0eycZ7"
    },
    {
        image: volumeOneImage,
        category: 'Anthology V1',
        link: "https://www.amazon.com/Strangest-Fiction-Anthology-1/dp/B0BJYQ1KG5"
    },
    {
        image: SFMug,
        category: '11oz Mug',
        link: "https://www.ebay.com/itm/314783598400"
    },
    {
        image: StrangestFictionPoster,
        category: 'Stickers',
        link: "https://www.ebay.com/itm/314213249485"
    },
];

const CategoriesCarousel = ({className}) => {
    const [swiper, setSwiper] = useState(null);
 
    const ref = useRef(null);
    
    const goNext = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slideNext();
        }
    };
    
    const goPrev = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slidePrev();
        }
    };

    const params = {
        slidesPerView: 4,
        spaceBetween: 30,
        loop: true,
        breakpoints: {
            1024: {
                slidesPerView: 4,
                spaceBetween: 30
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 30
            },
            640: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            320: {
                slidesPerView: 1,
                spaceBetween: 0
            }
        }
    };

    // Make the carousel images 255 x 415

    return (
        <div className={`feature_carousel_area mb40 ${className ? className : ''}`}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Heading title="Store Items"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {/*CAROUSEL START*/}
                        <div className="feature_carousel nav_style1">
                            <Swiper ref={ref} {...params}>
                                {news.map((item, i) => (
                                    <div key={i} className="single_post post_type6 post_type7">
                                        <div className="post_img gradient1">
                                            <a href={item.link}><img src={item.image} alt="thumb"/></a>
                                        </div>
                                        <div className="category-button-overlay">
                                            <a className="category-button" href={item.link}>
                                                {item.category}
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </Swiper>
                            <div className="navBtns">
                                <div onClick={goPrev} className="navBtn prevtBtn"><FontAwesome name="angle-left"/></div>
                                <div onClick={goNext} className="navBtn nextBtn"><FontAwesome name="angle-right"/></div>
                            </div>
                        </div>
                        {/*CAROUSEL END*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategoriesCarousel;