import React, {Fragment} from 'react';

import './CreateAccountModal.css';

import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import Button from "react-bootstrap/Button";

import {Link} from "react-router-dom";

export default class AccountCreatedSuccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {       
        };
    }


    render() {
        return ( 
            <>
               <Modal.Header closeButton>
                    <div className="center-content">
                        <h3>
                            Thank you!
                        </h3>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="center-content">
                        <div className="short-description">
                            Account Created Successfully! Welcome to Strangest Fiction, <b>{this.props.newusername}</b>. Click 'Write' to start writing short fiction today and visit your account page to add some additional information about yourself.
                        </div>
                    </div>
                    <div className="center-content">
                        <Button variant="secondary" size="lg" className="pad-right-10" onClick={() => {this.props.closeModal()}}>
                            Close
                        </Button>
                        <Link to="/account">
                            <Button variant="primary" size="lg" onClick={() => {this.props.closeModal();}}>
                                Account Info
                            </Button>
                        </Link>
                    </div>
                </Modal.Body>
            </>
        )
    }

}