import React, {Fragment} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function BlockedErrorModal(props) {
    return ( 
        <Modal show={props.showModal} onHide={props.closeModal} size="lg">
            <Modal.Header closeButton>
                <div className="center-content">
                    <h3>
                        Temporary Block
                    </h3>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="center-content">
                    <div className="short-description">
                        You have posted too many stories in a short amount of time or done something to violate our terms of service. Please contact the Strangest Fiction team if you believe this is a mistake.
                    </div>
                </div>
                <div className="center-content">
                    <Button variant="primary" size="lg" className="pad-right-10" onClick={() => {props.closeModal()}}>
                        Close
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}