import React, {Fragment} from 'react';
import BreadCrumb from "../../components/BreadCrumb";

import '../../components/primary-styles.css';

import '../NewsletterPage.css';
import PublicationCard from './PublicationCard';
import volumeOneImage from '../../new_images/publications/AnthologyCover.jpg';
import volumeTwoImage from '../../new_images/publications/AnthologyCoverV2.jpg';
import SideFeedContainer from '../SideFeedContainer';


const PublicationsPage = (props) => {

    return (
        <Fragment>
                <BreadCrumb title="Business"/>
                <div className="archives padding-top-50">
                    <div className="container">
                        <h3>Publications</h3>
                        <div className="straight-line"></div>
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="businerss_news">
                                    <div className='row'>
                                        <PublicationCard path={"anthology-vol-2"} title={"SF Anthology - Volume 2"} img={volumeTwoImage}/>
                                        <PublicationCard path={"anthology-vol-1"} title={"SF Anthology - Volume 1"} img={volumeOneImage}/>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-3">
                                <SideFeedContainer showCategories={false}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
    )
}

export default PublicationsPage;