import React from 'react';
import AdSense from 'react-adsense';

import CategoriesWidget from '../components/CategoriesWidget';
import TwitterFeed from '../components/TwitterFeed';
import { PayPalDonationButton } from '../components/PayPalDonationButton';
import NewsLetter from '../components/NewsLetter';
import FillOutSurvey from '../components/FillOutSurvey';
import InstagramLink from './InstagramLink';
import WriteStoryButton from './WriteStoryButton';

const SideFeedContainer = ({showAds, showCategories}) => {
    console.log(showAds);
    return (
        <>
            {/* {showAds && (
                <AdSense.Google
                    client='ca-pub-1960532248671989'
                    slot='2505755205'
                    style={{ display: 'block' }}
                    format='auto'
                    responsive='true'
                    layoutKey='-gw-1+2a-9x+5c'
                />
            )} */}
            <WriteStoryButton/>
            {(showCategories == null || showCategories) && <CategoriesWidget/>}
            <TwitterFeed/>
            <PayPalDonationButton/>
            <NewsLetter/>
            <InstagramLink/>
            <FillOutSurvey/>
        </>
    )
}

export default SideFeedContainer;