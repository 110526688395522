// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import 'firebase/storage';

const config = {
    apiKey: "AIzaSyBzf6ALgxHclGrGiU4DJlD5FRtQMhr-IKA",
    authDomain: "strangestfiction.com",
    projectId: "strangest-fiction",
    storageBucket: "strangest-fiction.appspot.com",
    messagingSenderId: "598195654626",
    appId: "1:598195654626:web:458143051d068b516f33bb",
    measurementId: "G-N5R87KMYZG"
};

// Initialize Firebase
firebase.initializeApp(config);
const db = firebase.firestore();
const storageRef = firebase.storage().ref();
const authRef = firebase.auth();
const analyticsRef = firebase.analytics();

// Export storage Ref:
export const getStorageRef = () => storageRef;

// Export db Ref:
export const getDBRef = () => db;

// Export auth Ref:
export const getAuthRef = () => authRef;

// Export analytics Ref:
export const getAnalyticsRef = () => analyticsRef;

const getAllStories = async () => {
    const snapshot = await db.collection('all-stories').get();
    return snapshot.docs.map(doc => doc.data());
}

const getAllUsers = async () => {
    const snapshot = await db.collection('users').get();
    return snapshot.docs.map(doc => doc.data());
}

const getAllPrompts = async () => {
    const snapshot = await db.collection('prompts').get();
    return snapshot.docs.map(doc => doc.data());
}

const getAllCompetitions = async () => {
    const snapshot = await db.collection('competitions').get();
    return snapshot.docs.map(doc => doc.data());
}

const getSubmissions = async () => {
    const snapshot = await db.collection('submissions').get();
    return snapshot.docs.map(doc => doc.data());
}

const getSubmissionDetails = async (s) => {
    const snapshot = await db.collection('submissions').doc(s).collection('submissions').get();
    return snapshot.docs.map(doc => doc.data());
}

export const requestSubmissionDetails = (dispatch, s) => {
    getSubmissionDetails(s).then(submissionDetails => {
        dispatch({type: "SET_SUBMISSION_DETAILS", payload: submissionDetails});
    })
}

export const getAllStoriesFB = (dispatch) => {
    dispatch({type: "SET_LOADING_STATE", payload: true});
    getAllStories().then(allStories => {
        dispatch({type: "GRAB_ALL_STORIES", payload: allStories})
    });
}

export const getInitialDataFB = (dispatch) => {
    dispatch({type: "GRAB_ALL_STORIES", payload: null});
    dispatch({type: "SET_LOADING_STATE", payload: true});
    return getAllStories().then(allStories => {
        getAllUsers().then(allUsers => {
            getAllPrompts().then(allPrompts => {
                getAllCompetitions().then(allCompetitions => {
                    dispatch({type: "GRAB_INITIAL_DATA", payload: {
                        allStories: allStories.sort((a, b) => { return b.timestamp - a.timestamp;}),
                        allUsers: allUsers,
                        allPrompts: allPrompts,
                        allCompetitions: allCompetitions
                    }})
                })
            })
        })
    })
}

export const regrabUserInfoFB = (dispatch, currentUserUID) => {
    let userRefrence = db.collection("users").doc(currentUserUID);
    let userData = null;

    userRefrence.get().then((doc) => {
        if (doc.exists) {
            userData = doc.data();
            let userImgRef = storageRef.child('user-images/' + currentUserUID + '/user_img.png');
            userImgRef.getDownloadURL()
            .then((url) => {
                // Insert url into an <img> tag to "download"
                userData.imgUrl = url;

                db.collection("users").doc(currentUserUID).update({
                    imgUrl: url
                })
        
                //console.log(userData);
            })
            .catch((error) => {
               //console.log(error);
                //console.log(userData);
            })
            .finally(() => {
                // this.setState(() => { return {currentUser: userData, checkForUser: true}});
                dispatch({type: "SET_CURRENT_USER", payload: {currentUser: userData, checkForUser: true}})
            })
        } else {
            // doc.data() will be undefined in this case
            //console.log("No such document!");
        }
    }).catch((error) => {
        //console.log("Error getting document:", error);
    });
}

export const logPageView = (pageName) => {
    firebase.analytics().logEvent('page_view', { page_name: pageName });
}

// fullDatabaseUpdate(allUsers) {
//     // FULL DB UPDATES IF REQUIRED, USE CAREFULLY
//     console.log("FULL DB UPDATE");
//     // if (allUsers) {
//     //     allUsers.map((user) => {
//     //         this.db.collection("users").doc(user.uid).update({
//     //             notifications: {
//     //                 hasActive: false,
//     //                 list: []
//     //             }
//     //         })
//     //     })
//     // }
// }

/* AUTHENTICATION ACTIONS */

// Sign In Clicked
export const onAuthenticateFB = (dispatch) => {
    firebase.auth().onAuthStateChanged((user) => {
        if(user){
            setTimeout(() => {
                let userRefrence = db.collection("users").doc(user.uid);
                let userData = null;
    
                //console.log(user);
    
                if (!user.emailVerified) {
                    //console.log("Please verify your email!")
                    dispatch({type: "SET_EMAIL_VERIFIED", payload: false});
                }
    
                userRefrence.get().then((doc) => {
                    if (doc.exists) {
                        userData = doc.data();
                        //this.setState(() => { return {currentUser: userData, checkForUser: true}});
                        dispatch({type: "SET_CURRENT_USER", payload: {
                            currentUser: userData,
                            checkForUser: true
                        }});
                        firebase.analytics().logEvent('current_user', {user_name: userData.username}); // re with dispatch

                        if (userData.isAdmin != null) {
                            getSubmissions().then(allSubmissions => {
                                dispatch({type: "GRAB_ALL_SUBMISSIONS", payload: allSubmissions})
                            })
                        }
    
                        // Check to see if notifications popup should be shown
                        if (userData.notifications.hasActive || userData.hasActiveMessage) {
                            // this.setState({showNotificationsPopup: true}); // re with dispatch
                            dispatch({type: "SET_NOTIFICATIONS_MODAL", payload: true});
                        }
                    } else {
                        // doc.data() will be undefined in this case
                        //console.log("No such document!");
                    }
                }).catch((error) => {
                   // console.log("Error getting document:", error);
                });
            }, 500);
        }
        else{
            //console.log('no user is logged in');
            //this.setState(() => { return {currentUser: null, checkForUser: true}}); //re with dispatch
            dispatch({type: "SET_CURRENT_USER", payload: {
                currentUser: null,
                checkForUser: true
            }});

            firebase.analytics().logEvent('current_user', {user_name: 'User not logged in'});
    
            // this.setState({showPromptCreateAccount: true})
        }
    });
}

// Verify Email
const verifyEmail = () => {
    firebase.auth().currentUser.sendEmailVerification().then(function() {
    // Email sent.
    }).catch(function(error) {
    // An error happened.
    });
}

// Google Sign Up
export const googleSignUpClickedFB = (dispatch) => {

    // Creates the firebase google auth provider object
    var provider = new firebase.auth.GoogleAuthProvider();

    // Gets the user info from modal
    firebase.auth()
    .signInWithPopup(provider)
    .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;

        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        //console.log(user);

        // Checks to see if the user has an account of if they are new
        db.collection("users").doc(user.uid).get().then((doc) => {
            if (doc.exists) {
                console.log("User already has an account!");
                //console.log(doc.data());
                dispatch({type: "SET_LOGIN_MODALS", payload: {
                    showLoginModal: false,
                    showCreateAccount: true,
                    showSuccess: false,
                    showPassReset: false
                }});
            } else {
                // doc.data() will be undefined in this case
                console.log("The user is new to the application!");

                db.collection("users").doc(user.uid).set({
                    username: user.email.split('@')[0],
                    email: user.email,
                    uid: user.uid,
                    fullname: user.displayName,
                    isProfilePublic: true,
                    description: "",
                    imgUrl: null,
                    numstories: 0,
                    viewcount: 0,
                    totalLikes: 0,
                    totalComments: 0,
                    notifications: {
                        hasActive: false,
                        list: []
                    },
                    dateJoined: Date.now()
                }).then(() => {
                    //this.props.regrabUserInfo();
                    // this.props.showSuccessMessage(user.email);
                    dispatch({type: "SET_LOGIN_MODALS", payload: {
                        showLoginModal: true,
                        showCreateAccount: false,
                        showSuccess: true,
                        showPassReset: false,
                        newUsername: user.username
                    }})
                    verifyEmail();

                    db.collection("email-list").add({
                        email: user.email
                    });
                })
            }
        })

    }).catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
    });
}

// Email & Password Sign Up
export const createAccountFB = (dispatch, username, email, fullName, password) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password)
    .then(authUser => {
        db.collection("users").doc(authUser.user.uid).set({
            username: username,
            email: email,
            uid: authUser.user.uid,
            fullname: fullName,
            isProfilePublic: true,
            description: "",
            imgUrl: null,
            numstories: 0,
            viewcount: 0,
            totalLikes: 0,
            totalComments: 0,
            notifications: {
                hasActive: false,
                list: []
            },
            dateJoined: Date.now()
        });
        db.collection("email-list").add({
            email: email
        })

        //this.props.showSuccessMessage(username);
        dispatch({type: "SET_LOGIN_MODALS", payload: {
            showLoginModal: true,
            showCreateAccount: false,
            showSuccess: true,
            showPassReset: false,
            newUsername: username
        }});
        verifyEmail();
    })
}

// Sign In with Email and Password
export const signInWithEmailAndPassword = (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
}

// Password reset
export const sendPasswordResetEmail = (email) => {
    return firebase.auth().sendPasswordResetEmail(email);
}

// Add Subscriber
export const addSubscriberEmailFB = (email) => {
    return db.collection("email-list").add({
        email: email
    });
}


/* WRITE STORY FUNCTIONS */

