import {combineReducers} from 'redux'
import metaReducer from "./metaReducer";
import testReducer from './testReducer';
import primaryReducer from './primaryReducer';

const rootReducer = combineReducers({
    meta: metaReducer,
    test: testReducer,
    primary: primaryReducer
});
export default rootReducer