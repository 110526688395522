import React, {Fragment} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import './CreateAccountModal.css';
import { sendPasswordResetEmail } from '../FirebaseActions';

export default class PasswordResetModal extends React.Component {
    constructor(props) {
        super(props);

        this.emailRef = React.createRef();
        this.emailError = React.createRef();

        this.state = {
            linkSent: false        
        };
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    handleSubmit() {
        if (this.validateEmail(this.emailRef.current.value)) {
            sendPasswordResetEmail(this.emailRef.current.value)
            .then(() => {
                //   setEmailHasBeenSent(true);
                //   setTimeout(() => {setEmailHasBeenSent(false)}, 3000);
                this.setState({linkSent: true});
            })
            .catch((e) => {
              //console.log(e);
            });
        } else {
            this.emailError.current.style.display="block";
        }
    }

    render() {
        return ( 
            <>
                <Modal.Header closeButton>
                    <div className="center-content">
                        <h3>
                            Password Reset
                        </h3>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {/* <div className="center-content">
                        <div className="please-sign-up">
                            Please Sign Up to Continue!
                        </div>
                    </div> */}
                    <div className="center-content">
                        <div className="short-description">
                            Enter the email associated with your account below to reset your password.
                        </div>
                    </div>

                    {!this.state.linkSent && (
                        <div className="center-content">
                            <Form className="sign-up-form" onSubmit={this.handleSubmit}>

                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label><b>Email address</b></Form.Label>
                                    <Form.Control type="email" placeholder="Enter email" ref={this.emailRef}/>
                                    <div className="error-message" ref={this.emailError}>
                                        Email is not formatted correctly!
                                    </div>
                                </Form.Group>

                                <div className="short-description">
                                    Already have an account? <a className="highlight" onClick={() => {this.props.showLogin()}}>Sign in here.</a>
                                </div>

                                <div className="bottom-modal-buttons">
                                    <Button variant="secondary" size="lg" className="pad-right-10" onClick={() => {this.closeModal()}}>
                                        Close
                                    </Button>
                                    <Button variant="primary" size="lg" onClick={() => {this.handleSubmit()}}>
                                        Send Link
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    )}

                    {this.state.linkSent && (
                        <div className="center-content">
                            <h2>Your email reset link was set! Keep a look out.</h2>
                        </div>
                    )}
                </Modal.Body>
            </>
        )
    }

}