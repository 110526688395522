import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import './AppContainer.css';
import { useDispatch, useSelector } from "react-redux";

const PromptUserToWrite = () => {
    // closeModalFromWrite() {
    //     this.setState(() => { return { showModal: false}})
    //     //console.log(this.state.loginWasSuccess)
    //     if (this.state.loginWasSuccess) {
    //         setTimeout(() => {
    //             this.props.history.push('/write');
    //         }, 300)
    //         this.setState({loginWasSuccess: false});
    //     }
    // }

    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.primary.currentUser);

    const showAccountModal = () => {
        dispatch({type: "SET_LOGIN_MODALS", payload: {
            showLoginModal: true,
            showCreateAccount: true,
            showSuccess: false,
            showPassReset: false
        }});
    }

    return ( 
        <>
            {currentUser != null && (
                <h3 className="user-writing-prompt">Have a story of your own you would like to share? Click <Link to="/write">here</Link> to start writing!</h3>
            )}
            {currentUser == null && (
                <h3 className="user-writing-prompt">Have a story of your own you would like to share? Click <Link onClick={showAccountModal}>here</Link> to start writing!</h3>
            )}
        </>
    )

}

export default PromptUserToWrite;