import React, {Fragment} from 'react';
import userImage from '../new_images/white-user-image.png';
import './CommentsSection.css';

import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import {Link} from "react-router-dom";
import { getDBRef } from '../FirebaseActions';

const ModalToggler = () => {
  return (
    <Modal show={this.props.showModal}>
      <ModalHeader>
        <ModalTitle>Hi</ModalTitle>
      </ModalHeader>
      <ModalBody>asdfasdf</ModalBody>
      <ModalFooter>This is the footer</ModalFooter>
    </Modal>
  );
};

export default class CommentsSection extends React.Component {

    constructor(props) {
        super(props);
        this.db = getDBRef();
        this.commentEmpty = React.createRef();
        this.state = {        
            showModal: false,
            comments: null,
            replyClicked: false,
            repliedComment: null
        };
    }

    postComment() {
        this.commentEmpty.current.style.display = 'none';
        if (this.props.currentUser != null) {
            if (document.getElementById('comment-input').value.length != 0) {
                let currentTime = Date.now();
                // Adding individual comment to story
                this.db.collection("all-stories").doc(this.props.story.id).collection('comments').doc(currentTime.toString()).set({
                    userInformation: this.props.currentUser,
                    comment: document.getElementById('comment-input').value,
                    timestamp: currentTime,
                    id: currentTime.toString(),
                    subcomments: []
                });

                // Increasing total comment count
                this.db.collection("all-stories").doc(this.props.story.id).update({
                    "allStoryInformation.commentsCount": (this.props.story.allStoryInformation.commentsCount + 1)
                });

                // Updating authors total comment count
                this.db.collection("users").doc(this.props.authorInfo.uid).update({
                    totalComments: this.props.authorInfo.totalComments + 1
                })

                // Sending comment notification to author
                if (this.props.authorInfo.uid != this.props.currentUser.uid) {
                    let newNotifications = this.props.authorInfo.notifications;
                    newNotifications.hasActive = true;
                    newNotifications.list.unshift({
                        message: `${this.props.currentUser.username} has commented on your story '${this.props.story.allStoryInformation.title}'!`,
                        read: false,
                        storyId: this.props.story.id
                    })
                    this.db.collection("users").doc(this.props.authorInfo.uid).update({
                        notifications: newNotifications
                    })
                }

                document.getElementById('comment-input').value = "";
                this.getComments();
            } else {
                this.commentEmpty.current.style.display = 'block';
            }
        } else {
            this.props.showAccountModal();
        }
    }

    replyClicked(comment) {
        if (this.props.currentUser != null) {
            this.setState(() => { return {replyClicked: true, repliedComment: comment}})
        } else {
            this.props.showAccountModal();
        }
    }

    replyUnclicked() {
        this.setState(() => { return {replyClicked: false, repliedComment: null}})
    }

    postReply() {
        let currentTime = Date.now();
        let newSubComment = {
            text: document.getElementById("sub-comment-input").value,
            userInformation: this.props.currentUser,
            timestamp: currentTime
        }
        let subComments = this.state.repliedComment.subcomments;
        subComments.push(newSubComment);
        this.db.collection("all-stories").doc(this.props.story.id).update({
            "allStoryInformation.commentsCount": (this.props.story.allStoryInformation.commentsCount + 1)
        })
        this.db.collection("all-stories").doc(this.props.story.id).collection('comments').doc(this.state.repliedComment.id).update({
            subcomments: subComments
        }).then(() => {
            this.getComments();
        })

        // Updating authors total comment count
        this.db.collection("users").doc(this.props.authorInfo.uid).update({
            totalComments: this.props.authorInfo.totalComments + 1
        })

        // Sending comment notification to author
        if (this.props.authorInfo.uid != this.props.currentUser.uid) {
            let newNotifications = this.props.authorInfo.notifications;
            newNotifications.hasActive = true;
            newNotifications.list.unshift({
                message: `${this.props.currentUser.username} has replied to a comment on your story '${this.props.story.allStoryInformation.title}'!`,
                read: false,
                storyId: this.props.story.id
            })
            this.db.collection("users").doc(this.props.authorInfo.uid).update({
                notifications: newNotifications
            })
        }
    }

    closeModal() {
        this.setState(() => { return {showModal: false}})
    }

    async fetchComments() {
        const snapshot = await this.db.collection('all-stories').doc(this.props.story.id).collection('comments').orderBy("timestamp", "asc").get();
        return snapshot.docs.map(doc => doc.data());
    }

    getComments() {
        this.fetchComments().then(comments => {
            //console.log(comments);
            this.setState(() => {return {comments: comments, replyClicked: false, repliedComment: null}})
        });
    }

    componentDidMount() {
        //console.log(this.props.currentUser);
        this.getComments();
    }

    render() {
        return ( 
            <div className="comments-section">
                {this.state.comments != null && (
                    <h2 className="comments-title">Comments ({this.props.story.allStoryInformation.commentsCount})</h2>
                )}
                <div className="thin-bar"/>
                <div className="comment-box-row">
                    {this.props.currentUser != null && this.props.currentUser.imgUrl != null && (
                        <div className="main-commenter-image-container">
                            <img src={this.props.currentUser.imgUrl} alt="User image" className="main-commenter-image"/>
                        </div>
                    )}
                    {this.props.currentUser != null && this.props.currentUser.imgUrl == null && (
                        <img src={userImage} alt="User image" className="user-image"/>
                    )}
                    {this.props.currentUser == null && (
                        <img src={userImage} alt="User image" className="user-image"/>
                    )}
                    <textarea class="form-control" id="comment-input" rows="3"></textarea>
                </div>
                <div className="comment-button-row">
                    <div class="btn btn-primary btn-lg active" role="button" aria-pressed="true" onClick={() => {this.postComment()}}>Comment</div>
                </div>

                <div className="social-media-saved" ref={this.commentEmpty}>
                    Your comment cannot be empty!
                </div>

                <div className="other-comments">
                    {this.state.comments != null && this.state.comments.map((comment, index) => {

                        let commentDate = new Date(comment.timestamp).toLocaleDateString("en-US");
                        //let commentTime = new Date(comment.timestamp).toLocaleTimeString("en-US");
                        let commentDateTime = commentDate; // + " " + commentTime;

                        return (
                            <>
                                <div className="other-comment">
                                    <div className="commenter-row">
                                        {comment.userInformation.imgUrl != null && (
                                            <img src={comment.userInformation.imgUrl} alt="User image" className="commenter-image"/>
                                        )}
                                        {comment.userInformation.imgUrl == null && (
                                            <img src={userImage} alt="User image" className="commenter-image"/>
                                        )}
                                        <div className="commenter-col">
                                            {comment.userInformation.fullname != "" && (
                                                <Link to={"/user/" + comment.userInformation.uid} className="commenter-name">
                                                    {comment.userInformation.fullname}
                                                </Link>
                                            )}
                                            {comment.userInformation.fullname == "" && (
                                                <Link to={"/user/" + comment.userInformation.uid} className="commenter-name">
                                                    {comment.userInformation.username}
                                                </Link>
                                            )}
                                            <div className="time-from-comment">
                                                {commentDateTime}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="comment-text-container">
                                        <div className="comment">
                                            {comment.comment}
                                        </div>
                                        {comment.subcomments != null && comment.subcomments.length != 0 && (
                                            <div className="sub-comments">
                                                {comment.subcomments.map((subcomment, subindex) => {
                                                    let subcommentDateTime = new Date(subcomment.timestamp).toLocaleDateString("en-US");

                                                    return (
                                                        <div>
                                                            <div className="commenter-row">
                                                                {subcomment.userInformation.imgUrl != null && (
                                                                    <img src={subcomment.userInformation.imgUrl} alt="User image" className="commenter-image"/>
                                                                )}
                                                                {subcomment.userInformation.imgUrl == null && (
                                                                    <img src={userImage} alt="User image" className="commenter-image"/>
                                                                )}
                                                                <div className="commenter-col">
                                                                    {subcomment.userInformation.fullname != "" && (
                                                                        <Link to={"/user/" + subcomment.userInformation.uid} className="commenter-name">
                                                                            {subcomment.userInformation.fullname}
                                                                        </Link>
                                                                    )}
                                                                    {subcomment.userInformation.fullname == "" && (
                                                                        <Link to={"/user/" + subcomment.userInformation.uid} className="commenter-name">
                                                                            {subcomment.userInformation.username}
                                                                        </Link>
                                                                    )}
                                                                    <div className="time-from-comment">
                                                                        {subcommentDateTime}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="comment-text-container">
                                                                <div className="comment">
                                                                    {subcomment.text}
                                                                </div>
                                                            </div>
                                                            <div className="thin-bar"/>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )}
                                        {!this.state.replyClicked && (
                                            <div class="reply-link" onClick={() => {this.replyClicked(comment)}}>Reply</div>
                                        )}
                                        {this.state.replyClicked && comment.timestamp != this.state.repliedComment.timestamp && (
                                            <div class="reply-link" onClick={() => {this.replyClicked(comment)}}>Reply</div>
                                        )}
                                        {this.state.replyClicked && comment.timestamp == this.state.repliedComment.timestamp &&  (
                                            <div>
                                                <div className="comment-box-row">
                                                    {this.props.currentUser != null && this.props.currentUser.imgUrl != null && (
                                                        <div className="main-commenter-image-container">
                                                            <img src={this.props.currentUser.imgUrl} alt="User image" className="main-commenter-image"/>
                                                        </div>
                                                    )}
                                                    {this.props.currentUser != null && this.props.currentUser.imgUrl == null && (
                                                        <img src={userImage} alt="User image" className="user-image"/>
                                                    )}
                                                    {this.props.currentUser == null && (
                                                        <img src={userImage} alt="User image" className="user-image"/>
                                                    )}
                                                    <textarea class="form-control" id="sub-comment-input" rows="3"></textarea>
                                                </div>
                                                <div className="comment-button-row">
                                                    <div class="btn btn-secondary btn-lg active pad-right-10" role="button" aria-pressed="true" onClick={() => {this.replyUnclicked()}}>Cancel</div>
                                                    <div class="btn btn-primary btn-lg active" role="button" aria-pressed="true" onClick={() => {this.postReply()}}>Reply</div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="thin-bar"/>
                            </>
                        )
                    })}
                    {this.state.comments != null && this.state.comments.length == 0 && (
                        <h3>No comments to show yet!</h3>
                    )}
                </div>
            </div>
        )
    }
}