import React, {Fragment} from 'react';
import BreadCrumb from "../../components/BreadCrumb";
import NewsLetter from "../../components/NewsLetter";
import InstagramLink from '../InstagramLink';

import '../../components/primary-styles.css';

import CategoriesWidget from '../../components/CategoriesWidget';
import { PayPalDonationButton } from '../../components/PayPalDonationButton';
import '../ContactUs.css';


export default class CompetitionTerms extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <Fragment>
                <BreadCrumb title="Business"/>
                <div className="archives padding-top-50">
                    <div className="container">
                        <h1>Competition Terms</h1>
                        <div className="straight-line"></div>
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="businerss_news">
                                    <p>Last updated: November 29th, 2022</p>
                                    <p>When submitting a story as part of the Strangest Fiction short story competitions, you agree to all of the terms listed below.</p>
                                    <h2>Story Content</h2>
                                    <ul>
                                    <li>
                                    <p>Stories that express an author's direct opinion of sexist, racist, homophobic, xenophobic, or other hateful content are prohibited. Character's in stories may express these opinions only if the writer specifies <b>Mature Content</b> so the user can decide whether to proceed.</p>
                                    </li>
                                    <li>
                                    <p>Stories containing graphic, violent, sexual, or other mature topics must specify <b>Mature Content</b> to warn the reader. In addition, we have the right to restrict any story that we deem too sexually graphic or violent in order to protect the readers on our site.</p>
                                    </li>
                                    <li>
                                    <p>Stories must contain <b>mostly correct</b> grammar and sentence structure. They must be written in English and contain an intermediate to advanced grasp of the written English language.</p>
                                    </li>
                                    <li>
                                    <p>Stories aimed at attacking specific individuals are strictly prohibited.</p>
                                    </li>
                                    </ul>
                                    <h2>Story Ownership</h2>
                                    <ul>
                                    <li>
                                    <p>The thoughts and text included in your story must be your own. You must hold the rights to use your story on the site.</p>
                                    </li>
                                    <li>
                                    <p>Stories that win a Strangest Fiction competiion/contest will automatically grant Strangest Fiction the rights to use the story for marketing purposes on the Strangest Fiction platforms. Further use of the story (publications, etc) will have to be granted permission by the author.</p>
                                    </li>
                                    <li>
                                    <p>Stories are ultimately the complete intelectual property of the writer. Stories can be removed from the site at anytime by the writer through the <b>Account Page</b>.</p>
                                    </li>
                                    <li>
                                    <p>While a user's story is posted on Strangest Fiction, administrators are allowed to use their stories for advertisements, newsletters, and other forms of marketing. Additional use of stories must be requested from the writer by administration.</p>
                                    </li>
                                    </ul>
                                    <h2>Other Details</h2>
                                    <ul>
                                    <li>
                                    <p>Duplicate submissions are not allowed.</p>
                                    </li>
                                    <li>
                                    <p>Winners will receive payment via Paypal. Other forms of payment may or may not be agreed upon by the publisher.</p>
                                    </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <CategoriesWidget/>
                                <PayPalDonationButton/>
                                <NewsLetter/>
                                <InstagramLink/>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};