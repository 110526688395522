import React, {Fragment} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import './CreateAccountModal.css';

import googleIcon from '../new_images/icons/google-icon.png';
import { googleSignUpClickedFB, signInWithEmailAndPassword } from '../FirebaseActions';

export default class LoginModal extends React.Component {
    constructor(props) {
        super(props);
        this.emailRef = React.createRef();
        this.nameRef = React.createRef();
        this.passwordRef = React.createRef();
        this.emailError = React.createRef();
        this.nameError = React.createRef();
        this.passError = React.createRef();

        this.state = {        
        };
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClose() {
        this.props.closeModal();
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    
    validatePassword(pass) {
        return pass.length != null && pass.length >= 8;
    }

    createAccount() {
        // Do firebase stuff
        this.props.showSuccessMessage();
    }

    handleSubmit() {
        this.emailError.current.style.display="none";
        this.passError.current.style.display="none";
        if (this.validateEmail(this.emailRef.current.value)) {
            if (this.validatePassword(this.passwordRef.current.value)) {
                // Do the firebase password check here!!

                signInWithEmailAndPassword(this.emailRef.current.value, this.passwordRef.current.value)
                .then(authUser => {
                    this.props.closeModal();
                })
                .catch(error => {
                    //console.log(error)
                    this.passError.current.style.display="block";
                })

            } else {
                // Password error message
                this.passError.current.style.display="block";
            }

        } else {
            // Email error message
            this.emailError.current.style.display="block";
        }
    }

    componentDidMount() {
        document.getElementById('up_btn').style.display = 'none';
    }

    componentWillUnmount() {
        document.getElementById('up_btn').style.display = 'block';
    }

    render() {
        return ( 
            <>
                <Modal.Header closeButton>
                    <div className="center-content">
                        <h3>
                            Join Strangest Fiction
                        </h3>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {/* <div className="center-content">
                        <div className="short-description">
                            Login to continue interacting with the Strangest Fiction community!
                        </div>
                    </div> */}

                    <div className="center-content">
                        <Button variant="primary" size="lg" className="google-login-button" onClick={() => {googleSignUpClickedFB()}}>
                            <img className="google-login-img" src={googleIcon} alt="google icon" />
                            Sign in with Google
                        </Button>
                    </div>

                    <div className="center-content">
                        <div className="or-line-divider-container">
                            <div className="or-line-divider"></div>
                            <div className="or-text">Or</div>
                            <div className="or-line-divider"></div>
                        </div>
                    </div>

                    <div className="center-content">
                        <Form className="sign-up-form" onSubmit={this.handleSubmit}>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label><b>Email address</b></Form.Label>
                                <Form.Control type="email" placeholder="Enter email" ref={this.emailRef}/>
                                <div className="error-message" ref={this.emailError}>
                                    Email is not formatted correctly!
                                </div>
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label><b>Password</b></Form.Label>
                                <Form.Control type="password" placeholder="Password" ref={this.passwordRef}/>
                                <div className="error-message" ref={this.passError}>
                                    Email or password are not correct!
                                </div>
                            </Form.Group>

                            <div className="short-description">
                                Don't have an account yet? <a className="highlight" onClick={() => {this.props.showCreate()}}>Sign up here.</a>
                            </div>

                            <div className="short-description">
                                Forgot your password? <a className="highlight" onClick={() => {this.props.forgotPass()}}>Click here.</a>
                            </div>

                            {/* <Form.Group controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Remember Me" />
                            </Form.Group> */}

                            <div className="bottom-modal-buttons">
                                <Button variant="secondary" size="lg" className="pad-right-10" onClick={() => {this.handleClose()}}>
                                    Close
                                </Button>
                                <Button variant="primary" size="lg" onClick={() => {this.handleSubmit()}}>
                                    Login
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </>
        )
    }

}