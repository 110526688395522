import React, {Fragment} from 'react';
import userImage from '../../new_images/white-user-image.png';
import '../CommentsSection.css';

import {Link} from "react-router-dom";

export default class ReviewComments extends React.Component {

    constructor(props) {
        super(props);
        this.db = this.props.firebase.firestore();
        this.commentEmpty = React.createRef();
        this.state = {        
            showModal: false,
            comments: null
        };
        this.closeModal = this.closeModal.bind(this);
    }

    postComment() {
        if (this.props.currentUser != null) {
            if (document.getElementById('comment-input').value.length != 0) {
                let currentTime = Date.now();
                this.db.collection("reviews").doc(this.props.reviewName).collection('comments').doc().set({
                    userInformation: this.props.currentUser,
                    comment: document.getElementById('comment-input').value,
                    timestamp: currentTime
                });
                document.getElementById('comment-input').value = "";
                this.getComments();
            } else {
                this.commentEmpty.current.style.display = 'block';
            }
        } else {
            this.setState(() => { return { showModal: true}});
        }
    }

    closeModal() {
        this.setState(() => { return {showModal: false}})
    }

    async fetchComments() {
        const snapshot = await this.db.collection('reviews').doc(this.props.reviewName).collection('comments').orderBy("timestamp", "asc").get();
        return snapshot.docs.map(doc => doc.data());
    }

    getComments() {
        this.fetchComments().then(comments => {
            //console.log(comments);
            this.setState(() => {return {comments: comments}})
        });
    }

    componentDidMount() {
        //console.log(this.props.currentUser);
        this.getComments();
    }

    render() {
        return ( 
            <div className="comments-section">
                {this.state.comments != null && (
                    <h2 className="comments-title">Comments ({this.state.comments.length})</h2>
                )}
                <div className="thin-bar"/>
                <div className="comment-box-row">
                    {this.props.currentUser != null && this.props.currentUser.imgUrl != null && (
                        <div className="main-commenter-image-container">
                            <img src={this.props.currentUser.imgUrl} alt="User image" className="main-commenter-image"/>
                        </div>
                    )}
                    {this.props.currentUser != null && this.props.currentUser.imgUrl == null && (
                        <img src={userImage} alt="User image" className="user-image"/>
                    )}
                    {this.props.currentUser == null && (
                        <img src={userImage} alt="User image" className="user-image"/>
                    )}
                    <textarea class="form-control" id="comment-input" rows="3"></textarea>
                </div>
                <div className="comment-button-row">
                    <div class="btn btn-primary btn-lg active" role="button" aria-pressed="true" onClick={() => {this.postComment()}}>Comment</div>
                </div>

                <div className="social-media-saved" ref={this.commentEmpty}>
                    Your comment cannot be empty!
                </div>

                <div className="other-comments">
                    {this.state.comments != null && this.state.comments.map((comment, index) => {

                        let commentDate = new Date(comment.timestamp).toLocaleDateString("en-US");
                        let commentTime = new Date(comment.timestamp).toLocaleTimeString("en-US");
                        let commentDateTime = commentDate + " " + commentTime;

                        return (
                            <>
                                <div className="other-comment">
                                    <div className="commenter-row">
                                        {comment.userInformation.imgUrl != null && (
                                            <img src={comment.userInformation.imgUrl} alt="User image" className="commenter-image"/>
                                        )}
                                        {comment.userInformation.imgUrl == null && (
                                            <img src={userImage} alt="User image" className="commenter-image"/>
                                        )}
                                        <div className="commenter-col">
                                            {comment.userInformation.fullname != "" && (
                                                <Link to={"/user/" + comment.userInformation.uid} className="commenter-name">
                                                    {comment.userInformation.fullname}
                                                </Link>
                                            )}
                                            {comment.userInformation.fullname == "" && (
                                                <Link to={"/user/" + comment.userInformation.uid} className="commenter-name">
                                                    {comment.userInformation.username}
                                                </Link>
                                            )}
                                            <div className="time-from-comment">
                                                {commentDateTime}
                                            </div>
                                            <div className="comment">
                                                {comment.comment}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="thin-bar"/>
                            </>
                        )
                    })}
                    {this.state.comments != null && this.state.comments.length == 0 && (
                        <h3>No comments to show yet!</h3>
                    )}
                </div>
            </div>
        )
    }
}