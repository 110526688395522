import React, {useState, useEffect, useContext} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Link, NavLink} from "react-router-dom";

import penIcon from '../new_images/icons/pen-icon.png';

const WriteStoryButton = (props) => {
    const currentUser = useSelector((state) => state.primary.currentUser);
    const dispatch = useDispatch();

    const showModalFromWrite = () => {
        dispatch({type: "SET_LOGIN_MODALS", payload: {showLoginModal: true, showCreateAccount: true, showSuccess: false, showPassReset: false}});
    }

    return (
        <div>
            {currentUser && (
                <Link className="side-write-button" to="/write">
                    <img src={penIcon} alt="temp icon" className="pen-icon"/>
                    <div className="top-button-text">
                        WRITE SHORT STORY
                    </div>
                </Link>
            )}
            {!currentUser && (
                <div className="side-write-button" onClick={() => {showModalFromWrite()}}>
                    <img src={penIcon} alt="temp icon" className="pen-icon"/>
                    <div className="top-button-text">
                        WRITE SHORT STORY
                    </div>
                </div>
            )}
        </div>
    )
}

export default WriteStoryButton;