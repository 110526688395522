import React, {Fragment, useEffect, useState} from 'react';
import BreadCrumb from "../../components/BreadCrumb";
import Button from "react-bootstrap/Button";
import { css, cx } from '@emotion/css';

import '../CategoryPages.css';

import '../../components/primary-styles.css';

import volumeOneImage from '../../new_images/publications/AnthologyCover.jpg';
import volumeTwoImage from '../../new_images/publications/AnthologyCoverV2.jpg';

import SideFeedContainer from '../SideFeedContainer';

const SinglePublicationPage = (props) => {

    const publication = (window.location.pathname).slice(14);

    return (
        <Fragment>
                <BreadCrumb title="Business"/>
                <div className="archives padding-top-50">
                    <div className="container">
                            <div className="row row-eq-height">
                                <div className="col-lg-9">
                                    {publication === 'anthology-vol-1' && (
                                        <div className="businerss_news">
                                            <center><h2>Strangest Fiction Anthology - Volume 1</h2></center>
                                            <center><p>Last updated: November 5, 2022</p></center>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <img src={volumeOneImage} alt={"Publication image for SF Anthology - Volume 1"} className="publication-image" />
                                                </div>
                                                <div className="col-md-6">
                                                    <br/>
                                                    <center><b>The Strangest Fiction Anthology is a collection of short stories written by a diverse group of talented fiction authors from strangestfiction.com. It is a showcase of the most popular science fiction, supernatural, horror, and thriller works on the site. Prepare to journey into the depths of space, listen to another sociopathic tale from your grandfather, confront the noise emanating from your attic, and much, much more. The collection will push your imagination and curiosity to their limits, and then ask a bit more from you. Sit back, relax, and enjoy the strange tales held within!</b></center>
                                                    <div className={css`display: flex; flex-direction: row;justify-content: center; padding-top: 20px;`}>
                                                        <a href='https://books2read.com/u/bwQyQy'>
                                                            <Button variant="primary" className="email-signup-button">
                                                                Ebook
                                                            </Button>
                                                        </a>
                                                    </div>
                                                    <div className={css`display: flex; flex-direction: row;justify-content: center; padding-top: 10px;`}>
                                                        <a href='https://amzn.to/3DYKJbZ'>
                                                            <Button variant="primary" className="email-signup-button">
                                                                Kindle Ebook
                                                            </Button>
                                                        </a>
                                                    </div>
                                                    <div className={css`display: flex; flex-direction: row;justify-content: center; padding-top: 10px;`}>
                                                        <a href='https://amzn.to/3t6O57m'>
                                                            <Button variant="primary" className="email-signup-button">
                                                                Paperback
                                                            </Button>
                                                        </a>
                                                    </div>
                                                    <div className={css`display: flex; flex-direction: row;justify-content: center; padding-top: 10px;`}>
                                                        <a href='https://www.goodreads.com/book/show/68055379-strangest-fiction-anthology---volume-1'>
                                                            <Button variant="primary" className="email-signup-button">
                                                                Goodreads
                                                            </Button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className={css`padding-top: 15px;`}>Featuring: Jon Richter, Titania Tempest, Steve DeGroof, Alex O'Neal, Jeanne Franc, Jim Kiernan, Josh Spicer, Kia Jones, Stephen Faulkner, Alex Mann, Mike Onofrio, E.L. McKenzie, William Merrill, Derek Wautlet, Darrell Grant, Henry Valerio, and Will Hershey.</p>
                                        </div>
                                    )}
                                    
                                    {publication === 'anthology-vol-2' && (
                                        <div className="businerss_news">
                                            <center><h2>Strangest Fiction Anthology - Volume 2</h2></center>
                                            <center><p>Last updated: August 13th, 2024</p></center>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <img src={volumeTwoImage} alt={"Publication image for SF Anthology - Volume 2"} className="publication-image" />
                                                </div>
                                                <div className="col-md-6">
                                                    <br/>
                                                    <center><b>Lock your doors, keep a light on, and stay alert because these works lurk in the shadows. The second installment of the Strangest Fiction Anthology features another array of bewildering content that will terrify, delight, and perplex the reader. This time, we’ve handpicked the most horrifying submissions from writers of all backgrounds. When you open this devilish collection, leap into unique tales about manipulative murderous televisions, camper-killing abominations of nature, ravenous rats, and much, much more.</b></center>
                                                    {/* <div className={css`display: flex; flex-direction: row;justify-content: center; padding-top: 20px;`}>
                                                        <a href='https://books2read.com/u/bwQyQy'>
                                                            <Button variant="primary" className="email-signup-button">
                                                                Ebook
                                                            </Button>
                                                        </a>
                                                    </div> */}
                                                    <div className={css`display: flex; flex-direction: row;justify-content: center; padding-top: 10px;`}>
                                                        <a href='https://a.co/d/0EejBZF'>
                                                            <Button variant="primary" className="email-signup-button">
                                                                Kindle Ebook
                                                            </Button>
                                                        </a>
                                                    </div>
                                                    <div className={css`display: flex; flex-direction: row;justify-content: center; padding-top: 10px;`}>
                                                        <a href='https://a.co/d/fJdM9qj'>
                                                            <Button variant="primary" className="email-signup-button">
                                                                Paperback
                                                            </Button>
                                                        </a>
                                                    </div>
                                                    <div className={css`display: flex; flex-direction: row;justify-content: center; padding-top: 10px;`}>
                                                        <a href='https://www.goodreads.com/book/show/216784387-strangest-fiction-anthology---volume-2'>
                                                            <Button variant="primary" className="email-signup-button">
                                                                Goodreads
                                                            </Button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className={css`padding-top: 15px;`}>Featuring: Eddie Spohn, B.K. Crafton, Gerald Jensen, Rick Hufford, Joshua Harding, Alyssa Milani, Jason A. Jones, Heather Miller, Joyce Bevc, Ell Orion, Christopher La Vigna, Britney Pellouchoud, L. Lyott, Brian Hawkins, and Michael Castillo.</p>
                                        </div>
                                    )}
                                </div>
                                <div className="col-lg-3">
                                    <SideFeedContainer/>
                                </div>
                            </div>
                    </div>
                </div>
            </Fragment>

    )
}

export default SinglePublicationPage;