function mostViewSort(data) {
    let initial = 0;
    let secondFirst = Math.floor(data.length / 2) - 1;
    let results = [];
    data.forEach((item, i) => {
        if (i % 2) {
            secondFirst += 1;
            results.push({...data[secondFirst], id: secondFirst + 1});
        } else {
            initial += 1;
            results.push({...data[initial], id: initial});
        }
    });
    return results;
}

function toFullMonth(month) {
    if (month === 0) return "January"
    else if (month === 1) return "February"
    else if (month === 2) return "March"
    else if (month === 3) return "April"
    else if (month === 4) return "May"
    else if (month === 5) return "June"
    else if (month === 6) return "July"
    else if (month === 7) return "August"
    else if (month === 8) return "September"
    else if (month === 9) return "October"
    else if (month === 10) return "November"
    else if (month === 11) return "December"
}

function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export {
    mostViewSort,
    toFullMonth,
    formatAMPM
};