import React, {Fragment} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import './CreateAccountModal.css';

import ReCAPTCHA from "react-google-recaptcha";

import googleIcon from '../new_images/icons/google-icon.png';
import { connect } from 'react-redux';
import {googleSignUpClickedFB, createAccountFB} from '../FirebaseActions';

class CreateAccountModal extends React.Component {
    constructor(props) {
        super(props);
        this.emailRef = React.createRef();
        this.nameRef = React.createRef();
        this.fullNameRef = React.createRef();
        this.passwordRef = React.createRef();
        this.emailError = React.createRef();
        this.nameError = React.createRef();
        this.passError = React.createRef();
        this.alreadyExistsError = React.createRef();
        this.usernameTooLongError = React.createRef();
        this.captchaFailedError = React.createRef();
        this.sixteenErrorMessage = React.createRef();

        this.state = {
            captchaPassed: false
        };
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onCaptchaChange = this.onCaptchaChange.bind(this);
    }

    handleClose() {
        this.props.onHide();
        document.getElementById('up_btn').style.display = 'block';
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    
    validatePassword(pass) {
        return pass.length != null && pass.length >= 8;
    }

    validateUsername(username) {
        let doesExist = this.props.allUsers.some((otherUser) => {
            return username == otherUser.username;
        })
        return !doesExist;
    }

    handleSubmit() {
        //console.log(document.getElementById('is-16').checked);
        this.emailError.current.style.display="none";
        this.nameError.current.style.display="none";
        this.passError.current.style.display="none";
        this.alreadyExistsError.current.style.display="none";
        this.sixteenErrorMessage.current.style.display="none";

        if (this.validateEmail(this.emailRef.current.value)) {
            if (this.nameRef.current.value.length !== 0 && this.nameRef.current.value.length < 20 ) {
                if (this.validatePassword(this.passwordRef.current.value)) {
                    if (this.validateUsername(this.nameRef.current.value)) {
                        if (this.state.captchaPassed) {
                            if (document.getElementById('is-16').checked) {
                                createAccountFB(this.props.dispatch, this.nameRef.current.value, this.emailRef.current.value, this.fullNameRef.current.value, this.passwordRef.current.value).catch((e) => {
                                    this.emailError.current.style.display="block";
                                })
                            } else {
                                this.sixteenErrorMessage.current.style.display="block";
                            }
                        } else {
                            // Captcha failed
                            this.captchaFailedError.current.style.display="block";
                        }
                    } else {
                        // Username exists error message
                        this.alreadyExistsError.current.style.display="block";
                    }
                } else {
                    // Password error message
                    this.passError.current.style.display="block";
                }
            } else {
                // Name error message
                this.nameError.current.style.display="block";
            }
        } else {
            // Email error message
            this.emailError.current.style.display="block";
        }
    }

    onCaptchaChange(value) {
        //console.log("Captcha value:", value);
        this.setState({captchaPassed: true});
    }

    componentDidMount() {
        document.getElementById('up_btn').style.display = 'none';
        if (document.getElementById('share-icon') != null) {
            document.getElementById('share-icon').style.display = 'none';
        }
    }

    componentWillUnmount() {
        document.getElementById('up_btn').style.display = 'block';
        if (document.getElementById('share-icon') != null) {
            document.getElementById('share-icon').style.display = 'block';
        }
    }

    render() {
        return ( 
            <>
                <Modal.Header closeButton>
                    <div className="center-content">
                        <h3>
                            Join Strangest Fiction
                        </h3>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {/* <div className="center-content">
                        <div className="please-sign-up">
                            Please Sign Up to Continue!
                        </div>
                    </div> */}
                    <div className="center-content">
                        <Button variant="primary" size="lg" className="google-login-button" onClick={() => {googleSignUpClickedFB(this.props.dispatch)}}>
                            <img className="google-login-img" src={googleIcon} alt="google icon" />
                            Sign in with Google
                        </Button>
                    </div>

                    <div className="center-content">
                        <div className="or-line-divider-container">
                            <div className="or-line-divider"></div>
                            <div className="or-text">Or</div>
                            <div className="or-line-divider"></div>
                        </div>
                    </div>

                    <div className="center-content">
                        <Form className="sign-up-form" onSubmit={this.handleSubmit}>
                            <div className="short-description">
                                Already have an account? <a className="highlight" onClick={() => {this.props.showLogin()}}>Sign in here.</a>
                            </div>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label><b>Email address</b></Form.Label>
                                <Form.Control type="email" placeholder="Enter email" ref={this.emailRef}/>
                                <div className="error-message" ref={this.emailError}>
                                    Email is not formatted correctly or already has an account!
                                </div>
                                <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label><b>Username</b></Form.Label>
                                <Form.Control type="text" placeholder="jsmith74" ref={this.nameRef}/>
                                <div className="error-message" ref={this.nameError}>
                                    Username cannot be empty and must be less than 20 characters!
                                </div>
                                <div className="error-message" ref={this.alreadyExistsError}>
                                    Username already exists!
                                </div>
                                <Form.Text className="text-muted">
                                Stories have the option to be published anonymously.
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label><b>Full Name</b> (Optional)</Form.Label>
                                <Form.Control type="text" placeholder="jsmith74" ref={this.fullNameRef}/>
                                {/* <div className="error-message" ref={this.nameError}>
                                    Name field cannot be empty!
                                </div> */}
                                <Form.Text className="text-muted">
                                Stories have the option to be published anonymously.
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label><b>Password</b></Form.Label>
                                <Form.Control type="password" placeholder="Password" ref={this.passwordRef}/>
                                <div className="error-message" ref={this.passError}>
                                    Password must be at least 8 characters!
                                </div>
                                <Form.Text className="text-muted">
                                Password must be at least 8 characters in length.
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label><b>Are you 16 years of age or older?</b></Form.Label>
                                <input type="checkbox" id="is-16" className="is-16"/>
                            </Form.Group>
                            <div className="error-message" ref={this.sixteenErrorMessage}>
                                    You must confirm that you are 16 years of age or older!
                            </div>

                            <ReCAPTCHA
                                sitekey="6LeBTXIaAAAAACg9OlPsZ5ZBBtpERaWwcVyJ8MC6"
                                onChange={this.onCaptchaChange}
                            /> <br/>
                            <div className="error-message" ref={this.captchaFailedError}>
                                    Please verify you are not a robot!
                            </div>

                            {/* <Form.Group controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Remember Me" />
                            </Form.Group> */}

                            <div className="bottom-modal-buttons">
                                <Button variant="secondary" size="lg" className="pad-right-10" onClick={() => {this.handleClose()}}>
                                    Close
                                </Button>
                                <Button variant="primary" size="lg" onClick={() => {this.handleSubmit()}}>
                                    Sign Up
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        allUsers: state.primary.allUsers,
        checkForUser: state.primary.checkForUser
    }
}

export default connect (mapStateToProps) (CreateAccountModal)