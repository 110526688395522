import React, {Fragment} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import './ShareStoryModal.css';

import twitterIcon from '../new_images/sm-logos/twitter-colored-icon.png';
import facebookIcon from '../new_images/sm-logos/facebook-colored-logo.png';
import tumblrIcon from '../new_images/sm-logos/tumblr-colored-icon.png';
import emailIcon from '../new_images/sm-logos/gmail-colored-icon.png';
import shareIcon from '../new_images/icons/share-icon.png';
import linkCircleIcon from '../new_images/icons/link-circle-icon.png';

import fullBlueStar from '../new_images/icons/full-blue-star.png';
import hollowBlueStar from '../new_images/icons/hollow-blue-star.png';
import thumbsUpOutline from '../new_images/thumbs/black-thumb-up-outline.png';
import blueThumbIcon from '../new_images/thumbs/blue-thumb-icon.png';
import commentsIcon from '../new_images/icons/comments-icon.png';

import EmailShare from 'react-email-share-link';
import { getAnalyticsRef, getDBRef } from '../FirebaseActions';

export default class ShareStoryModal extends React.Component {
    constructor(props) {
        super(props);
        this.db = getDBRef();
        this.state = {
            recentlyLiked: false,
            wasFavorited: false,
            messageText: ""
        };

        this.sendReaction = this.sendReaction.bind(this);
    }

    shareClicked(type) {
        //console.log(type);

        getAnalyticsRef().logEvent('share_clicked', { type: type});

        if (type === "facebook") {
            this.db.collection("all-stories").doc(this.props.storyInformation.id).update({
                "allStoryInformation.shares.facebook": (this.props.storyInformation.allStoryInformation.shares.facebook + 1)
            });
        } else if (type === "tumblr") {
            this.db.collection("all-stories").doc(this.props.storyInformation.id).update({
                "allStoryInformation.shares.tumblr": (this.props.storyInformation.allStoryInformation.shares.tumblr + 1)
            });
        } else if (type === "twitter") {
            this.db.collection("all-stories").doc(this.props.storyInformation.id).update({
                "allStoryInformation.shares.twitter": (this.props.storyInformation.allStoryInformation.shares.twitter + 1)
            });
        } else if (type === "email") {
            this.db.collection("all-stories").doc(this.props.storyInformation.id).update({
                "allStoryInformation.shares.email": (this.props.storyInformation.allStoryInformation.shares.email + 1)
            });
        } else if (type == "link") {
            this.db.collection("all-stories").doc(this.props.storyInformation.id).update({
                "allStoryInformation.shares.link": (this.props.storyInformation.allStoryInformation.shares.link + 1)
            });
        }

        // Sending shared notification to author
        if (!this.props.currentUser || (this.props.authorInfo.uid != this.props.currentUser.uid)) {
            let newNotifications = this.props.authorInfo.notifications;
            newNotifications.hasActive = true;
            newNotifications.list.unshift({
                message: `A reader has shared your story '${this.props.storyInformation.allStoryInformation.title}' using ${type}!!`,
                read: false,
                storyId: this.props.storyInformation.id
            })
            this.db.collection("users").doc(this.props.authorInfo.uid).update({
                notifications: newNotifications
            })
        }
      }

    linkIconClicked() {
        var copyText = document.createElement("input");
        copyText.type = "text";
        var link = "https://strangestfiction.com/story/" + this.props.storyInformation.id;
        copyText.value = link;
        document.body.appendChild(copyText);
        copyText.select();
        document.execCommand("copy");
        document.getElementById("link-copied").style.display = 'block';
        this.shareClicked("link");
    }

    sendReaction() {
        let updatedReactions = {};
        if (this.props.currentUser.reactions != null) {
            updatedReactions = this.props.currentUser.reactions;
        }
        updatedReactions[this.props.storyInformation.id] = true;

        this.db.collection("users").doc(this.props.currentUser.uid).update({
            reactions: updatedReactions
        })

        let thumbsUp = this.props.storyInformation.allStoryInformation.thumbsUp;
        thumbsUp.push(this.props.currentUser.uid);

        this.db.collection("all-stories").doc(this.props.storyInformation.id).update({
            "allStoryInformation.thumbsUp": thumbsUp
        })

        this.db.collection("users").doc(this.props.authorInfo.uid).update({
            totalLikes: this.props.authorInfo.totalLikes + 1
        })

        // Sending like notification to author
        if (this.props.authorInfo.uid != this.props.currentUser.uid) {
            let newNotifications = this.props.authorInfo.notifications;
            newNotifications.hasActive = true;
            newNotifications.list.unshift({
                message: `${this.props.currentUser.username} has liked your story '${this.props.storyInformation.allStoryInformation.title}'!!`,
                read: false,
                storyId: this.props.storyInformation.id
            })
            this.db.collection("users").doc(this.props.authorInfo.uid).update({
                notifications: newNotifications
            })
        }

        this.setState({recentlyLiked: true, messageText: 'You liked this story!'})
    }

    // Favorite icon was clicked
    favoriteClicked() {
        this.setState({wasFavorited: true, messageText: 'Story added to your favorites list!'});

        // Adding the favorite to the stories information
        let favorites = this.props.storyInformation.allStoryInformation.favorites;
        if (favorites == null) {
            favorites = [];
        }
        favorites.push(this.props.currentUser.uid);
        this.db.collection("all-stories").doc(this.props.storyInformation.id).update({
            "allStoryInformation.favorites": favorites
        })

        // Adding the favorite to the users profile
        let favoritedStories = this.props.currentUser.favorites;
        if (favoritedStories == null) {
            favoritedStories = [];
        }
        favoritedStories.push(this.props.storyInformation.id);
        this.db.collection("users").doc(this.props.currentUser.uid).update({
            "favorites": favoritedStories
        });

        // Sending favorited notification to author
        if (this.props.authorInfo.uid != this.props.currentUser.uid) {
            let newNotifications = this.props.authorInfo.notifications;
            newNotifications.hasActive = true;
            newNotifications.list.unshift({
                message: `${this.props.currentUser.username} has FAVORITED your story '${this.props.storyInformation.allStoryInformation.title}'!!`,
                read: false,
                storyId: this.props.storyInformation.id
            })
            this.db.collection("users").doc(this.props.authorInfo.uid).update({
                notifications: newNotifications
            })
        }

        this.props.regrabUserInfo();
    }

    // Unfavorite a story
    unfavoriteClicked() {
        this.setState({wasFavorited: false, messageText: 'Story removed from your favorites list.'});

            // Adding the favorite to the stories information
        let favorites = this.props.storyInformation.allStoryInformation.favorites;
        if (favorites == null) {
            favorites = [];
        }
        favorites = favorites.filter(i => i !== this.props.currentUser.uid);
        this.db.collection("all-stories").doc(this.props.storyInformation.id).update({
            "allStoryInformation.favorites": favorites
        })

        // Adding the favorite to the users profile
        let favoritedStories = this.props.currentUser.favorites;
        if (favoritedStories == null) {
            favoritedStories = [];
        }
        favoritedStories = favoritedStories.filter(i => i !== this.props.storyInformation.id);
        //console.log(favoritedStories);
        this.db.collection("users").doc(this.props.currentUser.uid).update({
            "favorites": favoritedStories
        })

        this.props.regrabUserInfo();
    }

    componentDidMount() {
        if (this.props.currentUser != null && this.props.currentUser.favorites != null && this.props.currentUser.favorites.includes(this.props.storyInformation.id)) {
            this.setState({wasFavorited: true});
        }
    }

    render() {
        //console.log(this.props.currentUser);
        return ( 
            <Modal show={this.props.showModal} onHide={this.props.closeModal} size="lg">
                <Modal.Header closeButton>
                    <div className="center-content">
                        <h3>Share and React</h3>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="reactions-mobile">
                        <div className="reaction-row-mobile" onClick={() => {this.sendReaction()}}>
                            {this.state.recentlyLiked && (
                                <img src={blueThumbIcon} alt="Like icon." className="reaction-icon"/>
                            )}
                            {!this.state.recentlyLiked && (
                                <>
                                    {this.props.currentUser != null && this.props.currentUser.reactions != null && this.props.currentUser.reactions[this.props.storyInformation.id] == true && (
                                        <img src={blueThumbIcon} alt="Like icon." className="reaction-icon"/>
                                    )}
                                    {(this.props.currentUser == null || this.props.currentUser.reactions == null || this.props.currentUser.reactions[this.props.storyInformation.id] != true) && (
                                        <img src={thumbsUpOutline} alt="Like icon." className="reaction-icon"/>
                                    )}
                                </>
                            )}
                            <a className="reaction-text">Like</a>
                        </div>
                        <div className="reaction-row-mobile" onClick={() => {this.props.closeModal(); this.props.scrollToComments()}}>
                            <img src={commentsIcon} alt="Comment icon." className="reaction-icon"/>
                            <a className="reaction-text">Comment</a>
                        </div>
                        {this.state.wasFavorited && (
                            <div className="reaction-row-mobile" onClick={() => {this.unfavoriteClicked()}}>
                                <img src={fullBlueStar} alt="Favorite icon." className="reaction-icon"/>
                                <a className="reaction-text">Favorite</a>
                            </div>
                        )}
                        {!this.state.wasFavorited && (
                            <div className="reaction-row-mobile" onClick={() => {this.favoriteClicked()}}>
                                <img src={hollowBlueStar} alt="Favorite icon." className="reaction-icon"/>
                                <a className="reaction-text">Favorite</a>
                            </div>
                        )}
                    </div>
                    <div className="message-text"> {this.state.messageText} </div>
                    <div className="horizontal-share-buttons" onClick={() => {getAnalyticsRef().logEvent('share_clicked', { type: 'any'}); console.log('clicked')}}>
                                <a class="twitter-share-button" href={"https://twitter.com/intent/tweet?text=Check out the short story " + this.props.storyInformation.allStoryInformation.title + "! You can find it on Strangest Fiction: https://strangestfiction.com/story/" + this.props.storyInformation.id} data-size="large" target="_blank" onClick={() => {this.shareClicked('twitter')}}><img src={twitterIcon} alt="twitter icon" className="medium-sm-logo"/></a>
                                <a href={"https://www.facebook.com/sharer/sharer.php?u=https://strangestfiction.com/story/" + this.props.storyInformation.id} class="share-popup" onClick={() => {this.shareClicked('facebook')}} target="_blank"><img src={facebookIcon} alt="twitter icon" className="medium-sm-logo" target="_blank"/></a>
                                <a href={"http://www.tumblr.com/share/link?url=https://strangestfiction.com/story/" + this.props.storyInformation.id} class="share-popup" onClick={() => {this.shareClicked('tumblr')}} target="_blank"><img src={tumblrIcon} alt="tumblr icon" className="medium-sm-logo"/></a>
                                <EmailShare email="mickey@mouse.com" subject={this.props.storyInformation.allStoryInformation.title} body={"Check out the short story " + this.props.storyInformation.allStoryInformation.title + "! You can find it on Strangest Fiction: https://strangestfiction.com/story/" + this.props.storyInformation.id + " "}>
                                    {link => (
                                        <a href={link} data-rel="external" onClick={() => {this.shareClicked('email')}}><img src={emailIcon} alt="email icon" className="medium-sm-logo"/></a>
                                    )}
                                </EmailShare>
                                <a><img src={linkCircleIcon} alt="twitter icon" className="medium-sm-logo" onClick={() => { this.linkIconClicked()}}/></a>
                                {/* <a href={"mailto:?subject=Strangest Fiction Short Story&amp;body=" + emailBody} title="Share by Email"><img src={emailIcon} alt="email icon" className="medium-sm-logo"/></a> */}
                    </div>
                    <div className="center-content">
                        <div id="link-copied" class="link-copied">Link copied to clipboard!</div>
                    </div>
                    <br/><br/>
                </Modal.Body>
            </Modal>
        )
    }

}