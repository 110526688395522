import React, {Fragment} from 'react';
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";

import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";

import'./StoryPage.css';
import '../components/primary-styles.css';

import './WriteStoryPage.css';

import PublishModalContainer from './PublishModalContainer';
import ConfirmSaveModal from './ConfirmSaveModal';
import CommunityGuidelinesModal from './CommunityGuidelinesModal';

import blankPicture from '../new_images/blank-picture.png';
import { connect } from 'react-redux';
import { getDBRef, getInitialDataFB, getStorageRef, logPageView } from '../FirebaseActions';

class WriteStoryPage extends React.Component {
    constructor(props) {
        super(props);

        this.titleError = React.createRef();
        this.descriptionError = React.createRef();
        this.textError = React.createRef();

        this.imgInput = React.createRef();
        this.promptSelector = React.createRef();

        this.state = {        
            storyTitle: "",
            storyDescription: "",
            storyText: "",
            scifiTag: "",
            superTag: "",
            horrorTag: "",
            fantasyTag: "",
            thrillerTag: "",
            histFicTag: "",
            showPublishModal: false,
            showSavedModal: false,
            showCommunityGuidelines: false,
            allStoryInformation: null,
            imgUrl: null,
            showLoading: false,
            selectedPromptId: null,
            selectedPromptTitle: null,
            showPrompts: false,
            competitionInfo: null
        };
        this.goToAccountPage = this.goToAccountPage.bind(this);
        this.imageUploaded = this.imageUploaded.bind(this);
        this.promptChanged = this.promptChanged.bind(this);
      }

      tagClicked(tagName) {
        if (tagName == "scifi") {
            if (this.state.scifiTag == "") {
                this.setState({scifiTag: "tag-highlighted"});
            } else { this.setState({scifiTag: ""})}
        } else if (tagName == "super") {
            if (this.state.superTag == "") {
                this.setState({superTag: "tag-highlighted"});
            } else { this.setState({superTag: ""})}
        } else if (tagName == "horror") {
            if (this.state.horrorTag == "") {
                this.setState({horrorTag: "tag-highlighted"});
            } else { this.setState({horrorTag: ""})}
        } else if (tagName=="fantasy") {
            if (this.state.fantasyTag == "") {
                this.setState({fantasyTag: "tag-highlighted"})
            } else { this.setState({fantasyTag: ""})}
        } else if (tagName == "thriller") {
            if (this.state.thrillerTag == "") {
                this.setState({thrillerTag: "tag-highlighted"});
            } else { this.setState({thrillerTag: ""})}
        } else if (tagName == "histfic") {
            if (this.state.histFicTag =="") {
                this.setState({histFicTag: "tag-highlighted"});
            } else { this.setState({histFicTag: ""})}
        }
      }

      publishClicked() {
        if (this.state.storyTitle != "") {
            if (this.state.storyDescription != "") {
                if (document.getElementById("story-text").innerHTML.length > 200) {
                    this.publishStory();
                } else {
                    this.textError.current.style.display="block";
                }
            } else {
                this.descriptionError.current.style.display="block";
            }
        } else {
            this.titleError.current.style.display="block";
        }
      }

      publishStory() {
          //console.log(document.getElementById("story-text").innerHTML);

          let tags = [];
          if (this.state.scifiTag != "") { tags.push('Science Fiction'); }
          if (this.state.superTag != "") { tags.push('Supernatural'); }
          if (this.state.horrorTag != "") { tags.push('Horror'); }
          if (this.state.fantasyTag != "") { tags.push('Fantasy'); }
          if (this.state.thrillerTag != "") { tags.push('Thriller'); }
          if (this.state.histFicTag != "") { tags.push('Historical Fiction'); }

          let anonSlider = document.getElementById('anon-slider').checked;
          let matureSlider = document.getElementById('mature-content-slider').checked;
          let publicationSlider = document.getElementById('publication-slider').checked;

          let competitionId = null;
          if (this.state.competitionInfo) competitionId = this.state.competitionInfo.id;

          let allStoryInformation = {
                title: this.state.storyTitle,
                tags: tags,
                description: this.state.storyDescription,
                publishAnon: anonSlider,
                matureContent: matureSlider,
                publicationSlider: publicationSlider,
                storyText: document.getElementById("story-text").innerHTML,
                username: this.props.currentUser.username,
                fullname: this.props.currentUser.fullname,
                viewcount: 0,
                thumbsUp: [],
                thumbsDownCount: 0,
                commentsCount: 0,
                shares : {
                    facebook: 0,
                    tumblr: 0,
                    twitter: 0,
                    email: 0,
                    link: 0
                },
                imgURL: null,
                promptId: this.state.selectedPromptId,
                competitionId: competitionId
          }

          // Checks the case where a user did upload a story image
          if (this.state.imgUrl != null && this.imgInput.current.files[0] != null) {
            //console.log(this.imgInput.current.files[0]);

            // Create a root reference
            var storageRef = getStorageRef();

            // Create a reference to 'images/mountains.jpg'
            var newImageRef = storageRef.child('story-images/' + this.state.storyTitle + '/story_img.jpg');

            // 'file' comes from the Blob or File API
            this.setState({showLoading: true});
            newImageRef.put(this.imgInput.current.files[0]).then((snapshot) => {
                snapshot.ref.getDownloadURL().then((downloadURL) => {
                    //console.log(downloadURL);
                    allStoryInformation.imgURL = downloadURL;
                    this.setState(() => { return {showLoading: false, allStoryInformation: allStoryInformation, showPublishModal: true}});
                })
            });
          }
          // Checks case where user did not upload a story image 
          else {
            this.setState(() => { return {allStoryInformation: allStoryInformation, showPublishModal: true}});
          }
      }

      closePublishModal() {
          this.setState({showPublishModal: false})
      }

      saveStory() {
        let db = getDBRef();
        db.collection("users").doc(this.props.currentUser.uid).collection("saved-stories").add({
            title: this.state.storyTitle,
            description: this.state.storyDescription,
            text: document.getElementById("story-text").innerHTML
        })
        this.setState({showSavedModal: true});
      }

      saveClicked() {
        //console.log(document.getElementById("story-text").innerHTML);
        if (this.state.storyTitle != "") {
            if (this.state.storyDescription != "") {
                if (document.getElementById("story-text").innerHTML.length > 200) {
                    this.saveStory();
                } else {
                    this.textError.current.style.display="block";
                }
            } else {
                this.descriptionError.current.style.display="block";
            }
        } else {
            this.titleError.current.style.display="block";
        }
      }

      closeSaveModal() {
        this.setState({showSavedModal: false, storyTitle: "", storyDescription: ""});
        document.getElementById('story-text').innerHTML = "Edit Me";
        this.props.history.push('/account');
      }

      goToAccountPage() {
        this.setState({showPublishModal: false, storyTitle: "", storyDescription: ""});
        document.getElementById('story-text').innerHTML = "Edit Me";
        getInitialDataFB(this.props.dispatch).then(() => {
            this.props.history.push('/account');
        });
      }

      imageUploaded() {
        const [file] = this.imgInput.current.files;
        if (file) {
          this.setState({imgUrl: URL.createObjectURL(file) });
        }
      }

      preLoadPrompt(promptId) {
        let promptInfo = this.props.allPrompts.find(element => element.id == promptId);
        this.setState({selectedPromptId: promptId, selectedPromptTitle: promptInfo.title});
      }

      promptChanged() {
          let promptInfo = this.props.allPrompts.find(element => element.id == this.promptSelector.current.value);
          this.setState({selectedPromptId: promptInfo.id, selectedPromptTitle: promptInfo.title, showPrompts: false});
      }

      setCompetition(competitionId) {
        let competitionInfo = this.props.allCompetitions.find(element => element.id == competitionId);
        this.setState({competitionInfo: competitionInfo});
      }

      componentDidMount() {
          document.getElementById('story-title-input').focus();
          if (this.props.savedInfo != null) {
              this.setState(() => { return {storyTitle: this.props.savedInfo.title, storyDescription: this.props.savedInfo.description}})
              document.getElementById('story-text').innerHTML = this.props.savedInfo.text;
          }

          // Check for competition to preload
          var url = new URL(window.location.href);
          var competitionId = url.searchParams.get("competition");
          if (competitionId != null) this.setCompetition(competitionId);

          // Check for prompt to preload
          var url = new URL(window.location.href);
          var promptId = url.searchParams.get("prompt");
          if (promptId != null) this.preLoadPrompt(promptId);

          logPageView(window.location.pathname);
      }

      componentWillUnmount() {
        this.props.dispatch({type: "SET_SAVED_STORY", payload: null});
          //console.log("unmounted");
      }
    
      render() {
          return (
            <Fragment>
            <div className="archives post post1 story-container">
                <span className="space-30"/>
                <div className="container">
                    <div className="row background-grey">
                        <div className="col-12 col-md-10 col-lg-8 m-auto">
                            <div className="space-20"/>

                            <div className="story-title-container">
                                <div className="story-title">
                                    Title
                                </div>
                                <input type="text" value={this.state.storyTitle} onChange={(e) => {this.setState({storyTitle: e.target.value})}} id="story-title-input" className="story-title-input" autofocus/>
                                <div className="error-message" ref={this.titleError}>
                                    Your story must have a title!
                                </div>
                            </div>
                        
                            <div className="story-title-container">
                                <div className="story-title">
                                    Tags
                                </div>
                                <div className="tag-options">
                                    <div className={"tag-option " + this.state.scifiTag} onClick={() =>  {this.tagClicked("scifi")}}>Science Fiction</div>
                                    <div className={"tag-option " + this.state.superTag} onClick={() =>  {this.tagClicked("super")}}>Supernatural</div>
                                    <div className={"tag-option " + this.state.horrorTag} onClick={() =>  {this.tagClicked("horror")}}>Horror</div>
                                    <div className={"tag-option " + this.state.fantasyTag} onClick={() =>  {this.tagClicked("fantasy")}}>Fantasy</div>
                                    <div className={"tag-option " + this.state.thrillerTag} onClick={() =>  {this.tagClicked("thriller")}}>Thriller</div>
                                    <div className={"tag-option " + this.state.histFicTag} onClick={() =>  {this.tagClicked("histfic")}}>Historical Fiction</div>
                                </div>
                            </div>

                            {this.state.competitionInfo != null && (
                                <div className="story-title-container">
                                    <div className="story-title">
                                        Competition
                                    </div>
                                    {this.state.competitionInfo != null && (
                                        <div className='write-story-prompt-info'>
                                            <i>{this.state.competitionInfo.title}</i>
                                            <a className="edit-prompt-link" onClick={() => {this.setState({competitionInfo: null})}}>Remove</a>
                                        </div>
                                    )}
                                </div>
                            )}

                            {this.state.competitionInfo == null && (
                                <div className="story-title-container">
                                    <div className="prompt-option-title">
                                        Prompt (optional)
                                    </div>
                                    {this.state.selectedPromptId != null && !this.state.showPrompts && (
                                        <div className='write-story-prompt-info'>
                                            <i>{this.state.selectedPromptTitle}</i>
                                            <a className="edit-prompt-link" onClick={() => {this.setState({showPrompts: true})}}>Edit</a> - <a className="remove-prompt-link" onClick={() => {this.setState({selectedPromptId: null, selectedPromptTitle: null})}}>Remove</a>
                                        </div>
                                    )}
                                    {this.state.selectedPromptId == null && !this.state.showPrompts && (
                                        <div className="no-prompt-selected">No prompt selected for this story. Does this story satisfy one of our prompts? <a className="edit-prompt-link" onClick={() => {this.setState({showPrompts: true})}}>Click here.</a></div>
                                    )}
                                    {this.state.showPrompts && (
                                        <>
                                            <select name="options" className="prompt-selector" id="sort-selector" onChange={this.promptChanged} ref={this.promptSelector}>
                                                {this.props.allPrompts != null && this.props.allPrompts.map((prompt) => {
                                                    return <option value={prompt.id}>{prompt.title}</option>
                                                })}
                                            </select>
                                            <a className="remove-prompt-link" onClick={() => {this.setState({selectedPromptId: null, selectedPromptTitle: null, showPrompts: false})}}>Remove</a>
                                        </>
                                    )}
                                </div>
                            )}

                            <div className="story-title-container">
                                <div className="story-title">
                                    Description
                                </div>
                                <div className="story-page-message">
                                    Max of 250 Characters
                                </div>
                                <textarea type="text" maxlength="250" rows="3" value={this.state.storyDescription} onChange={(e) => {this.setState({storyDescription: e.target.value})}} id="story-title-input" className="story-description-input" autofocus/>
                                <div className="error-message" ref={this.descriptionError}>
                                    Your story must have a description!
                                </div>
                            </div>

                            <div className="story-title-container">
                                <div className="story-title">
                                    Image Upload
                                </div>

                                {this.state.imgUrl != null && (
                                    <img src={this.state.imgUrl} alt="profile-image" className="uploaded-image"/>
                                )}
                                {this.state.imgUrl == null && (
                                    <img src={blankPicture} alt="profile-image" className="uploaded-image"/>
                                )}

                                <Form className="new-image-input">
                                    <Form.Group>
                                        <Form.File id="exampleFormControlFile1" onChange={this.imageUploaded} ref={this.imgInput} />
                                    </Form.Group>
                                </Form>
                            </div>

                            <div className="story-title-container">
                                <div className="story-title">
                                    Options
                                </div>
                                <div className="make-name-public">
                                    <div className="story-question-text">
                                        Publish the story anonymously? (Name and image hidden)
                                    </div>
                                    <label class="switch">
                                        <input type="checkbox" id="anon-slider"/>
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <div className="make-name-public">
                                    <div className="story-question-text">
                                        Does your story contain mature content?
                                    </div>
                                    <label class="switch">
                                        <input type="checkbox" id="mature-content-slider"/>
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <div className="make-name-public">
                                    <div className="story-question-text">
                                        Would you like your story to be considered for upcoming publications?
                                    </div>
                                    <label class="switch">
                                        <input type="checkbox" id="publication-slider"/>
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>

                            <div className="story-title-container">
                                <div className="story-title">
                                    Story Text
                                </div>
                                <div className="story-page-message">
                                    Write to your heart's content! (Copy and pasting content from other editors will retain special formatting)
                                </div>
                                <div contenteditable="true" id="story-text" value={this.state.storyText} onChange={(e) => {this.setState({storyText: e.target.value})}} className="story-text-input">
                                    Edit me
                                </div>
                                <div className="error-message" ref={this.textError}>
                                    Your story must have at least 250 characters!
                                </div>
                                {/* <div className="story-page-message">
                                    Warning: We reserve the right to hide stories from view that do not meet the community policies.
                                </div> */}
                                <br/>
                                <div className="story-question-text">
                                        Please ensure that your story meets the <Link onClick={() => { this.setState({showCommunityGuidelines: true})}}>Community Guidelines</Link> before publishing it.
                                </div>
                            </div>

                            <div className="submit-buttons-row">
                                <Button variant="primary" className="save-for-later button-shadow" size="lg" onClick={() => {this.saveClicked()}}>Save for Later</Button>
                                <Button variant="primary" className="button-shadow" size="lg" onClick={() => {this.publishClicked()}}>Publish</Button>
                            </div>

                            <div className="space-20"/>

                            {this.state.showLoading && (
                                <Fragment>
                                    <div class="d-flex justify-content-center">
                                        <Spinner animation="border" variant="primary" className="loading-spinner">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </div>
                                </Fragment>
                            )}

                            <PublishModalContainer selectedPromptId={this.state.selectedPromptId} showModal={this.state.showPublishModal} closeModal={() => {this.closePublishModal()}} allStoryInformation={this.state.allStoryInformation} goToAccount={this.goToAccountPage}/>
                            <ConfirmSaveModal showModal={this.state.showSavedModal} closeModal={() => {this.closeSaveModal()}}/>
                            <CommunityGuidelinesModal showModal={this.state.showCommunityGuidelines} closeModal={() => {this.setState({showCommunityGuidelines: false})}} />
                        </div>
                        
                    </div>
                    <div className="experiencing-issues-question">
                        Experiencing issues with the site or have questions? Contact us <Link to="/contact">here</Link>.
                    </div>
                    {/* <div className="space-60"/>
                <ThreeRecentStories/> */}
                </div>
            </div>
        </Fragment>
          )
      }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.primary.currentUser,
        allPrompts: state.primary.allPrompts,
        savedInfo: state.primary.savedStoryInfo,
        allCompetitions: state.primary.allCompetitions
    }
}

export default connect (mapStateToProps) (WriteStoryPage)