import React, {Fragment} from 'react';
import { useEffect, useState } from 'react';
import BreadCrumb from "../components/BreadCrumb";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";

import './CategoryPages.css';

import '../components/primary-styles.css';
import SideFeedContainer from './SideFeedContainer';
import {useDispatch} from 'react-redux'

const AnnouncementPage = (props) => {

    const [showLoginModal, setShowLoginModal] = useState(false);
    const [loginWasSuccess, setLoginWasSuccess] = useState(false);

    // const closeModalFromWrite = () => {
    //     setShowLoginModal(false);
    //     if (loginWasSuccess) {
    //         setTimeout(() => {
    //             props.history.push('/write');
    //         }, 300)
    //         setLoginWasSuccess(false);
    //     }
    // }
    
    // const wasSuccess = () => {
    //     setLoginWasSuccess(true);
    // }

    const dispatch = useDispatch();

    useEffect(() => {
        //console.log((window.location.pathname).slice(15));
    }, [])

    return (
        <Fragment>
                <BreadCrumb title="Business"/>
                <div className="archives padding-top-50">
                    <div className="container">
                        {prompt != null && (
                            <div className="row row-eq-height">
                                <div className="col-lg-9">
                                    <div className="businerss_news">
                                        <h2>Strangest Fiction Anthology - Coming October 2022!</h2>
                                        <p>Last updated: June 11, 2022</p>
                                        <p>Strangest Fiction is now recruiting authors for our Fiction Anthology coming October 2022! Post your best works on our site for free consideration! Create an account if you don’t have one already and then click the “Post” link below.</p>
                                        <p>We will be bringing together talented writers to showcase the talent of our up and coming authors. We will not discriminate based on previous publishing history and are looking to showcase writers of all experience levels. As always we are primarily gathering supernatural, thriller, horror, and science fiction works, but we would be glad to read any work you would consider “strange” in a broad sense.</p>
                                        <p>We will let all authors know of final decisions by <b>August 1st, 2022.</b></p>
                                        <p>For any questions feel free to use the “Contact Us” page or reach out to us at <a href="mailto:hello@strangestfiction.com">hello@strangestfiction.com</a>. You can also message either of our admins, @sitemaster or @jspicer331. </p> 
                                        <p>We are looking forward to reading all of your strange stories! Happy Writing 📝!</p>
                                        {props.currentUser != null && (
                                            <Link to="/write"><Button variant="primary" size="lg" className="send-message-button">Post Story for Consideration</Button></Link>
                                        )}
                                        {props.currentUser == null && (
                                            <Button variant="primary" size="lg" className="send-message-button" onClick={() => dispatch({type: "SET_LOGIN_MODALS", payload: {showLoginModal: true, showCreateAccount: true, showSuccess: false,showPassReset: false}})}>Post Story for Consideration</Button>
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <SideFeedContainer/>
                                </div>
                            </div>
                        )}
                        
                    </div>
                </div>
            </Fragment>

    )
}

export default AnnouncementPage;