import React, {Fragment} from 'react';
import BreadCrumb from "../../components/BreadCrumb";
import {Link} from "react-router-dom";
import NewsLetter from "../../components/NewsLetter";

// images
import CategoriesWidget from '../../components/CategoriesWidget';

import timeMachineImage from '../../new_images/time_machine.jpg';
import thumbsUpImage from '../../new_images/thumbs_up.png';

import '../SingleReviewPage.css';

import CommentSectionReviews from './CommentSectionReviews';
import ThreeRecentStories from '../../components/ThreeRecentStories';

export default class ReviewTheTimeMachine extends React.Component {

    constructor(props) {
        super(props);
        this.state = {        
        };
    }

    componentDidMount() {
        this.props.firebase.analytics().logEvent('page_view', { page_name: window.location.pathname });
    }

    render() {
        return ( 
            <Fragment>
            <div className="archives post post1">
                <BreadCrumb className="padding-top-30" title="Archive / post 1"/>
                <span className="space-30"/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="shadow6">
                                <div className="padding20 dark_footer_bg">
                                    <div className="space-30"/>
                                    <h2 className="review-title">The Time Machine Review</h2>
                                    <div className="row">
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="single_post post_type6 post_type7">
                                                <Link><img src={timeMachineImage} alt="thumb" className="review-book-image"/></Link>
                                                <div className="single_post_text">
                                                    <div className="meta5"><Link>Science Fiction</Link>
                                                        <Link>March 8th, 2021</Link>
                                                    </div>
                                                    <h4>
                                                        <Link>The Time Machine - H.G. Wells</Link>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="short-review">
                                            The Time Machine is one of the most defining Science Fiction novels of our time. It is the timeless story of every human's greatest curiosity being entertained by traveling forward through the cosmos into the future. 
                                            </div>
                                            <div className="review-sections">
                                                <div className="review-section">
                                                    <div className="review-section-bar"/>
                                                    <div className="review-section-title">
                                                        OUR VOTE
                                                    </div>
                                                    <div className="review-decision">
                                                        <img src={thumbsUpImage} alt="thumb up" className="review-decision-icon"/>
                                                        <div className="review-decision-text">
                                                            Buy it
                                                        </div>
                                                    </div>
                                                    <div className="review-section-bar"/>
                                                    <div className="review-section-title">
                                                        USERS VOTE
                                                    </div>
                                                    <div className="review-decision">
                                                        <img src={thumbsUpImage} alt="thumb up" className="review-decision-icon"/>
                                                        <div className="review-decision-text">
                                                            Buy it
                                                        </div>
                                                    </div>
                                                    <div className="review-section-bar"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="space-20"/>
                                    <div className="single_post_heading">
                                        <h1>Full Review</h1>
                                    </div>
                                </div>
                                <div className="padding20 dark_footer_bg main-review">
                                            <p>
                                            The Time Machine story is told through the eyes of a house guest of the unnamed “Time Traveler”. At the start of the story, we get a glimpse into his research of a vehicle that can move objects along the fourth dimension: time. The logic at the beginning of the novel is shockingly simple and hard to argue with. It goes that we can move along three dimensions with total flexibility: up and down, forward and back, so why would we not be able to do the same with the fourth dimension. 
                                            </p>
                                            <p>
                                            This revolutionary novel in the history of science fiction is most fascinating to me because the logic for the “science” behind the “science fiction” cannot be denied. Any science that is made up of complicated spacecraft, for instance, can get discounted by the reader as fictional because of how inaccurate it is by modern scientific standards. H.G. Wells, however, only used very simple logic to argue for why the potential for time travel exists and it kept me hooked throughout the book thinking about the possibilities.
                                            </p>
                                            <p>
                                            The Time Traveler’s story itself is a tale of what humanity will look like 800,000 years from our own. What he encounters speaks for itself in terms of what path humanity took to be completely divided by class and by wealth. H.G.Wells builds a world divided into two specific forms of humans: small and peaceful creatures on the surface in constant fear of the dark and the subterranean humans who have evolved to live and thrive in the dark. Wells describes how the surface dwellers who must have forced the Morlocks into the ground now live in fear of them at night and live their days in ignorant bliss.
                                            </p>
                                            <p>
                                            One hundred years after the novel was written, the themes of The Time Machine are more true than ever. The wealth gap is ever-increasing and people at the bottom ladder of societies all over the world barely earn enough to provide. Technology enables us to learn more in our lifetimes than ever before, it also enables us to live our lives without doing any real critical thinking because we have no more problems to solve. All of the information is given to us freely. The Time Machine explores what happens when the wealth gap and technology never stop. 
                                            </p>
                                            <p>
                                            This book is a must-read for any science fiction lover and contains essential themes for people from all walks of life. It is available in hard copy and as an E-Book from Amazon! 
                                            </p>
                                </div>
                            </div>
                            <div className="space-30"/>
                            {/* <PostOnePagination className="next_prv_single padding20 shadow6 next_prv_single3 dark-2"/> */}
                            <CommentSectionReviews reloadAllStories={this.props.reloadAllStories} allUsers={this.props.allUsers} reviewName="time_machine" currentUser={this.props.currentUser} firebase={this.props.firebase}/>
                        </div>
                        <div className="col-lg-4">
                            <div className="space-50"/>
                            <CategoriesWidget/>
                            <NewsLetter firebase={this.props.firebase}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-60"/>
            <ThreeRecentStories allStories={this.props.allStories} allUsers={this.props.allUsers} history={this.props.history}/>
        </Fragment>
        )
    }
};