import React, {Fragment} from "react";
import Button from "react-bootstrap/Button";
import MainMenu from "../components/MainMenu";

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    componentDidCatch(error, info) {
      // Display fallback UI
      this.setState({ hasError: true });
      // You can also log the error to an error reporting service
      // logErrorToMyService(error, info);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
            <Fragment>
                <div className="story-not-found-container">
                    <h1>An Error Occured!</h1>
                    <p>An error occured while processing your request, please try again later.</p>
                    <Button variant="primary" size="lg" onClick={() => {this.props.history.push("/"); this.setState({hasError: false})}}>Return to Home</Button>
                </div>
            </Fragment>
        )
      }
      return this.props.children;
    }
}

export default ErrorBoundary;