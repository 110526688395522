import React from 'react';
import Button from "react-bootstrap/Button";

export default class FillOutSurvey extends React.Component {

    constructor(props) {
        super(props);
        this.state = {        
        };
    }

    render() {
        return (
            <div className="newsletter-signup-container">
                <h2 className={`widget-title`}>Feedback Survey</h2>
                <p>Please fill out our survey to let us know what you think of the site so far and what you'd like to see in the future! </p>
                <div className="space-10"/>
                <div className="signup_form">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLScXI7X5n2e2KdGscpjxfLRyKVrvI98DL14paGJxckT55IkNzQ/viewform?usp=sf_link" className="full">
                        <Button variant="primary" className="email-signup-button" size="lg" onClick={this.submitClicked}>
                            Take Survey
                        </Button>
                    </a>
                    <div className="space-10"/>
                    {/* <p>We hate spam as much as you do</p> */}
                </div>
            </div>
        );
    }
};