import React, {Fragment} from 'react';
import BreadCrumb from "../../components/BreadCrumb";
import {Link} from "react-router-dom";
import NewsLetter from "../../components/NewsLetter";

// images
import CategoriesWidget from '../../components/CategoriesWidget';

import theKeepImage from '../../new_images/the_keep.jpg';
import thumbsUpImage from '../../new_images/thumbs_up.png';

import '../SingleReviewPage.css';

import CommentSectionReviews from './CommentSectionReviews';
import ThreeRecentStories from '../../components/ThreeRecentStories';

export default class ReviewTheKeep extends React.Component {

    constructor(props) {
        super(props);
        this.state = {        
        };
    }

    componentDidMount() {
        this.props.firebase.analytics().logEvent('page_view', { page_name: window.location.pathname });
    }

    render() {
        return ( 
            <Fragment>
            <div className="archives post post1">
                <BreadCrumb className="padding-top-30" title="Archive / post 1"/>
                <span className="space-30"/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="shadow6">
                                <div className="padding20 dark_footer_bg">
                                    <div className="space-30"/>
                                    <h2 className="review-title">The Keep Review</h2>
                                    <div className="row">
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="single_post post_type6 post_type7">
                                                <Link><img src={theKeepImage} alt="thumb" className="review-book-image"/></Link>
                                                <div className="single_post_text">
                                                    <div className="meta5"><Link to="/">Supernatural</Link>
                                                        <Link>December 3, 2020</Link>
                                                    </div>
                                                    <h4>
                                                        <Link>The Keep - F. Paul Wilson</Link>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="short-review">
                                                F. Paul Wilson brings together the stories of father and daughter, the struggle of living in Nazi occupation, and fear of the supernatural together to create a riveting and haunting story.
                                            </div>
                                            <div className="review-sections">
                                                <div className="review-section">
                                                    <div className="review-section-bar"/>
                                                    <div className="review-section-title">
                                                        OUR VOTE
                                                    </div>
                                                    <div className="review-decision">
                                                        <img src={thumbsUpImage} alt="thumb up" className="review-decision-icon"/>
                                                        <div className="review-decision-text">
                                                            Buy it
                                                        </div>
                                                    </div>
                                                    <div className="review-section-bar"/>
                                                    <div className="review-section-title">
                                                        USERS VOTE
                                                    </div>
                                                    <div className="review-decision">
                                                        <img src={thumbsUpImage} alt="thumb up" className="review-decision-icon"/>
                                                        <div className="review-decision-text">
                                                            Buy it
                                                        </div>
                                                    </div>
                                                    <div className="review-section-bar"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="space-20"/>
                                    <div className="single_post_heading">
                                        <h1>Full Review</h1>
                                    </div>
                                </div>
                                <div className="padding20 dark_footer_bg main-review">
                                            <p>
                                            On the surface, The Keep is a timeless story of good versus evil, the natural versus the supernatural, the story of overcoming the boogeyman. This novel is much more layered than that, however. There are several stories tied into one which is what makes the story so impressive.                                            </p>
                                            <p>
                                            The first story we get introduced to is that of a Nazi captain leading his unit to a keep in the Transylvanian Alps of Romania in order to be Hitler’s eyes on a path the Soviet Union might take to stab Germany in the back. The captain’s leadership style is that of a more standard military leader and is disturbed by many of the acts of more violent portions of the German army such as the SS. When soldiers start dying at the keep, however, a group of SS soldiers is dispatched to rectify the situation. The moral battle between the members of the German army (fighting for their country) and the SS command (fighting for the Nazi ideology) becomes strikingly clear.
                                            </p>
                                            <p>
                                            Another significant storyline is that the only presumed hope for Nazi soldiers dealing with a supernatural terror is to call upon the help of a Jewish professor, Theodore Cuza, who has some knowledge of the threat. The Nazis need his help but despise him, and this ultimately leads both of them to lead more about the inner workings of the other.
                                            </p>
                                            <p>
                                            The Keep is a riveting story that is littered with excellent thematic material and will surely be a joy to read if you enjoy supernatural historical fiction stories. You can buy a hard copy or E-Book from Amazon today!
                                            </p>
                                </div>
                            </div>
                            <div className="space-30"/>
                            {/* <PostOnePagination className="next_prv_single padding20 shadow6 next_prv_single3 dark-2"/> */}
                            <CommentSectionReviews reloadAllStories={this.props.reloadAllStories} allUsers={this.props.allUsers} reviewName="the_keep" currentUser={this.props.currentUser} firebase={this.props.firebase}/>
                        </div>
                        <div className="col-lg-4">
                            <div className="space-50"/>
                            <CategoriesWidget/>
                            <NewsLetter firebase={this.props.firebase}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-60"/>
            <ThreeRecentStories allStories={this.props.allStories} allUsers={this.props.allUsers} history={this.props.history}/>
        </Fragment>
        )
    }
};