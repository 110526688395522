import React from 'react';
import {Link} from "react-router-dom";

import union from '../../doc/img/icon/union.png';

import sciFiBanner from '../../new_images/scifi_banner.jpg';
import supernaturalBanner from '../../new_images/supernatural_banner.jpg';
import horrorBanner from '../../new_images/horror_banner.jpg';
import fantasyBanner from '../../new_images/fantasy_banner.jpg';
import thrillerBanner from '../../new_images/thriller_banner.jpg';
import histFicBanner from '../../new_images/histfic-banner.jpg';

import '../primary-styles.css';

const categories = [
    {
        small_img: union,
        big_image: sciFiBanner,
        link: '/category/scifi',
        title: 'Science Fiction',
    },
    {
        small_img: union,
        big_image: supernaturalBanner,
        link: '/category/supernatural',
        title: 'Supernatural',
    },
    {
        small_img: union,
        big_image: horrorBanner,
        link: '/category/horror',
        title: 'Horror',
    },
    {
        small_img: union,
        big_image: fantasyBanner,
        link: '/category/fantasy',
        title: 'Fantasy',
    },
    {
        small_img: union,
        big_image: thrillerBanner,
        link: '/category/thriller',
        title: 'Thriller',
    },
    {
        small_img: union,
        big_image: histFicBanner,
        link: '/category/histfic',
        title: 'Historical Fiction',
    },
];

const CategoriesWidget = () => {
    return (
        <div className="widget category">
            <div className="row">
                <div className="col-6 align-self-center">
                    <h2 className="widget-title categories-title">Categories</h2>
                </div>
            </div>
            <ul>
                {categories.map((item, i) => (
                    <li key={i}>
                        <Link to={item.link} style={{background: `url(${item.big_image})`}}> <span>{item.title}</span>
                            <img src={item.small_img} alt="category"/>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CategoriesWidget;