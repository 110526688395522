import React, {Fragment} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import './CreateAccountModal.css';

export default class ConfirmPublishModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {        
        };
    }

    render() {
        return ( 
            <Modal show={this.props.showModal} onHide={this.props.closeModal} size="lg">
                <Modal.Header closeButton>
                    <div className="center-content">
                        <h3>
                            Confirm Publication
                        </h3>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="center-content">
                        {this.props.allStoryInformation != null && (
                            <div className="short-description">
                                Are you sure you would like to publish your work <b>{this.props.allStoryInformation.title}</b>? It will now be publicly viewable.
                            </div>
                        )}
                    </div>
                    <div className="center-content">
                        <Button variant="secondary" size="lg" className="pad-right-10" onClick={() => {this.props.closeModal()}}>
                            No, continue working
                        </Button>
                        <Button variant="primary" size="lg" className="pad-right-10" onClick={() => {this.props.publishClicked()}}>
                            Publish
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

}