import React, {useState, useEffect, useContext} from 'react';
import './StickyAccountFooter.css';
import Button from "react-bootstrap/Button";
import LowerFooterLogo from '../new_images/icons/white-chevron.png';
import { useSelector, useDispatch } from 'react-redux';


const StickyAccountFooter = (props) => {

    const [showModal, setShowModal] = useState(false);
    const [anchor, setAnchor] = useState(true);

    const isLoginModalOpen = useSelector((state) => state.primary.loginModals.showLoginModal);
    const currentUser = useSelector((state) => state.primary.currentUser);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isLoginModalOpen || currentUser) setAnchor(false);
    }, [isLoginModalOpen])

    return (
        <>
            {anchor && (
                <>
                    {/* <div className="lower-sticky-footer">
                        <img src={LowerFooterLogo} alt="Lower footer logo" className="home-logo" onClick={() => { setAnchor(false)}} />
                    </div> */}
                    <div className="sticky-footer">
                        <div className="sticky-footer-objects">
                            <div className="sticky-footer-row">
                                <h5 className="join-anchor-text">Join the Strangest Fiction community!</h5>
                                <Button variant="secondary" className="create-anchor-button" onClick={() => { dispatch({type: "SET_LOGIN_MODALS", payload: {showLoginModal: true,showCreateAccount: true,showSuccess: false,showPassReset: false}}); setAnchor(false);}}>
                                    Create Account
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default StickyAccountFooter;