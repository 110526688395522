import React from 'react';
import SubscribedNewsLetterModal from '../../containers/SubscribedNewsLetterModal';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { addSubscriberEmailFB } from '../../FirebaseActions';

export default class NewsLetter extends React.Component {

    constructor(props) {
        super(props);
        this.emailRef = React.createRef();
        this.state = {        
            subscribed: false
        };
        this.submitClicked = this.submitClicked.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    submitClicked() {
        //console.log(this.emailRef.current.value);
        if (this.validateEmail(this.emailRef.current.value)) {
            addSubscriberEmailFB(this.emailRef.current.value).then(() => {
                this.setState({subscribed: true});
            })
        }
    }

    closeModal() {
        this.setState({subscribed: false})
        this.emailRef.current.value = "";
    }

    render() {
        return (
            <div className="newsletter-signup-container">
                <h2 className={`widget-title`}>News Letter</h2>
                <p>Sign up to receive email updates from the Strangest Fiction team and our reading recommendations. </p>
                <div className="space-10"/>
                <div className="signup_form">
                    <Form.Group controlId="formBasicEmail">
                        <Form.Control type="email" placeholder="Your email address" ref={this.emailRef}/>
                        {/* <div className="error-message" ref={this.emailError}>
                            Email is not formatted correctly!
                        </div> */}
                    </Form.Group>
                    <Button variant="primary" className="email-signup-button" onClick={this.submitClicked}>
                        Sign Up
                    </Button>
                    <div className="space-10"/>
                    {/* <p>We hate spam as much as you do</p> */}
                </div>
                <SubscribedNewsLetterModal closeModal={this.closeModal} showModal={this.state.subscribed} />
            </div>
        );
    }
};