import React, {useState, useEffect, useRef} from 'react';
import {Button} from 'react-bootstrap';
import {Link} from "react-router-dom";
import { toFullMonth } from '../../utils/commonFunctions';
import MessageUserModal from './MessageUserModal';
import GenericErrorModal from '../GenericErrorModal';
import ChatWindow from './ChatWindow';

import darkUserIcon from '../../new_images/icons/dark-user-icon.png';
import previousIcon from '../../new_images/icons/previous-icon.png';
import nextIcon from '../../new_images/icons/next-icon.png';
import plusIcon from '../../new_images/icons/white-plus-icon.png';

import './Messages.css';
import { getDBRef } from '../../FirebaseActions';

const Messages = (props) => {
    const [showSingle, setShowSingle] = useState(false);
    const [showUserModal, setShowUserModal] = useState(false);
    const [isFirstMessage, setIsFirstMessage] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);

    const [userInfo, setUserInfo] = useState(null);
    const [allMessages, setAllMessages] = useState(null);

    const [chatInfo, setChatInfo] = useState(null);

    const [showGenericModal, setShowGenericModal] = useState(false);

    const db = getDBRef();

    const resetVals = () => {
        setShowSingle(false); 
        setChatInfo(null);
        setIsFirstMessage(false);
    }

    const messageClicked = (relevantMessages, previewInfo) => {
        setUserInfo(previewInfo);
        setChatInfo(relevantMessages);
        setShowSingle(true);
    }

    const userClicked = (clickedUserInfo) => {
        setIsFirstMessage(true);
        setUserInfo(clickedUserInfo);
        setShowSingle(true);
        setShowUserModal(false);
    }

    const updateChatInfo = (info) => {
        setChatInfo(info);
    }

    const fetchMessagesDBCall = async () => {
        const snapshot = await db.collection('users').doc(props.currentUser.uid).collection('messages').orderBy("lastSent", "desc").get();
        return snapshot.docs.map(doc => doc.data());
    }

    const fetchMessages = async () => {
        fetchMessagesDBCall().then(messages => {
            setAllMessages(messages);
            checkIfHasNotifications();
            if (initialLoad) checkForURLParams(messages);
            setInitialLoad(false);
        })
    }

    const checkIfHasNotifications = async () => {

        if (allMessages && props.currentUser.hasActiveMessage) {
            let shouldRemoveDot = true;

            allMessages.map((message) => {
                if (!message.hasRead) {
                    shouldRemoveDot = false;
                }
            });
    
            if (shouldRemoveDot) {
                db.collection("users").doc(props.currentUser.uid).update({
                    hasActiveMessage: false
                }).then(() => {
                    props.regrabUserInfo();
                })
            }
        }
    }

    const checkForURLParams = (messages) => {
        // Check URL params
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.has("sendMessageTo")) {
            const userIdToSend = urlParams.get("sendMessageTo");
            if (messages.filter(o => o.userId == userIdToSend).length == 0) {
                let userInformation = props.allUsers.find(e => e.uid == userIdToSend);
                //console.log(userInformation);
                userClicked(userInformation);
            } else {
                setShowGenericModal(true);
            }
            props.history.push({ search: '?messages'})
        } else {
            props.history.push({ search: '?messages'})
        }
    }

    useEffect(() => {
        // Fetch all the user messages
        fetchMessages();
    }, [])

    useEffect(() => {
        // Updating chat info after allMessages update
        if (chatInfo) {
            setChatInfo(allMessages.find(o => o.id === chatInfo.id));
        }
        // Checking if there are still notifications after allMessages update
        checkIfHasNotifications();
    }, [allMessages])

    // useEffect(() => {
    //     console.log(props.currentUser);
    //     if (props.currentUser.updateMessages) {
    //         fetchMessages();
    //     }
    // }, [props.currentUser])

    return (
        <div className="messages-page">
            {showSingle && <ChatWindow updateChatInfo={updateChatInfo} updateMessages={() => fetchMessages()} currentUser={props.currentUser} hideSingle={resetVals} chatInfo={chatInfo} isFirstMessage={isFirstMessage} userInfo={userInfo}/>}
            {!showSingle && (
                <>
                    <div className="messages-header-bar">
                        <div className='center-vert'>
                            <h3>My Messages</h3>
                        </div>
                        <Button variant="primary" size="lg" className="send-message-button" onClick={() => setShowUserModal(true)}>New Message<img src={plusIcon} alt="plus icon" className="small-plus"/></Button>
                    </div>

                    <div className="weak-line"/>
                    
                    {allMessages && allMessages.length != 0 && (
                        <div className="messages-list">
                            {allMessages.map((message) => {

                                let previewUserInfo = props.allUsers.find(o => o.uid === message.userId);

                                return (
                                    <div className="message-preview" onClick={() => messageClicked(message, previewUserInfo)}>
                                        <div className="together">
                                            {!message.hasRead && (
                                                <div className="notification-dot-preview-container"><div className="notification-dot-preview" /></div>
                                            )}
                                            {previewUserInfo.imgUrl != null && (
                                                <img src={previewUserInfo.imgUrl} alt="profile-image" className="image"/>
                                            )}
                                            {previewUserInfo.imgUrl == null && (
                                                <img src={darkUserIcon} alt="profile-image" className="image"/>
                                            )}
                                            <div className="names">
                                                {previewUserInfo.fullname != "" && (
                                                    <div className="full">
                                                        {previewUserInfo.fullname}
                                                    </div>
                                                )}
                                                <div className="username">
                                                    @{previewUserInfo.username}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="center-vert">
                                            <img src={nextIcon} alt="next icon" className="prev-icon"/>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )}

                    {(allMessages == null || allMessages.length == 0) && (
                        <>
                            <center>You have no messages to display.</center>
                            <div className="weak-line"/>
                        </>
                    )}
                       
                </>
            )}
            <b>Note: Messages are asynchronous, meaning you'll have to refresh the page to get the latest updates.</b>
            <GenericErrorModal showModal={showGenericModal} closeModal={() => {setShowGenericModal(false)}}/>
            <MessageUserModal allMessages={allMessages} allUsers={props.allUsers} showModal={showUserModal} closeModal={() => setShowUserModal(false)} clickedUser={userClicked}/>
        </div>
    )
}

export default Messages;