import React, {Fragment} from 'react';
import BreadCrumb from "../components/BreadCrumb";
import NewsLetter from "../components/NewsLetter";
import CategoriesWidget from '../components/CategoriesWidget';
import InstagramLink from './InstagramLink';

import sciFiBannerBig from '../new_images/science_fiction_big_banner.jpg';
import supernaturalBannerBig from '../new_images/supernatural_big_banner.jpg';
import fantasyBigBanner from '../new_images/fantasy_big_banner.jpg';
import horrorBannerBig from '../new_images/horror_big_banner.jpg';
import thrillerBannerBig from '../new_images/thriller_big_banner.jpg';
import histFicBigImage from '../new_images/histfic-big-image.jpg';

import FillOutSurvey from '../components/FillOutSurvey';

import './CategoryPages.css';

import '../components/primary-styles.css';

import PromptUserToWrite from './PromptUserToWrite';
import StoryPreview from './StoryPreview';
import { PayPalDonationButton } from '../components/PayPalDonationButton';
import TwitterFeed from '../components/TwitterFeed';
import SideFeedContainer from './SideFeedContainer';
import { connect } from 'react-redux';
import {logPageView} from '../FirebaseActions';

class CategoryPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sortedStories: null,
            categoryAbbr: (window.location.pathname).slice(10),
            categoryName: ""     
        };
        this.sortSelector = React.createRef();

        this.sortClicked = this.sortClicked.bind(this);
    }



    storyClicked(story) {
        this.props.history.push('/story/' + story.id);
    }

    sortClicked() {
        //console.log(this.sortSelector.current.value);
        if (this.sortSelector.current.value == 'date') {
            this.setState({sortedStories: this.state.sortedStories.sort((a, b) => { return b.timestamp - a.timestamp;})});
        } else if (this.sortSelector.current.value == 'mostreads') {
            this.setState({sortedStories: this.state.sortedStories.sort((a, b) => { return (b.allStoryInformation.viewcount) - (a.allStoryInformation.viewcount)})});
        } else if (this.sortSelector.current.value == 'mostreact') {
            this.setState({sortedStories: this.state.sortedStories.sort((a, b) => { return (b.allStoryInformation.commentsCount + b.allStoryInformation.thumbsUp.length) - (a.allStoryInformation.commentsCount + a.allStoryInformation.thumbsUp.length)})});
        } else if (this.sortSelector.current.value == 'longest') {
            this.setState({sortedStories: this.state.sortedStories.sort((a, b) => { return b.allStoryInformation.storyText.split(' ').length - a.allStoryInformation.storyText.split(' ').length})});
        } else if (this.sortSelector.current.value == 'shortest') {
            this.setState({sortedStories: this.state.sortedStories.sort((a, b) => { return a.allStoryInformation.storyText.split(' ').length - b.allStoryInformation.storyText.split(' ').length})});
        } else if (this.sortSelector.current.value == 'poplate') {
            let sortedByDate = this.state.sortedStories.sort((a, b) => { return b.timestamp - a.timestamp;});
            let recentPortion = Math.round(sortedByDate.length / 3);
            let firstPart = sortedByDate.slice(0, recentPortion);
            firstPart.sort((a, b) => { return (b.allStoryInformation.viewcount + b.allStoryInformation.thumbsUp.length) - (a.allStoryInformation.viewcount + a.allStoryInformation.thumbsUp.length)});
            let secondPart = sortedByDate.slice(-(sortedByDate.length - recentPortion));
            this.setState({sortedStories: firstPart.concat(secondPart)});
        }
    }

    setCategoryInfo() {
        logPageView(window.location.pathname);
        //console.log((window.location.pathname).slice(10));

        let categoryAbbr = (window.location.pathname).slice(10);
        let categoryName = "";

        if (categoryAbbr == 'scifi') { categoryName = 'Science Fiction'}
        else if (categoryAbbr == 'supernatural') { categoryName = 'Supernatural'}
        else if (categoryAbbr == 'horror') { categoryName = 'Horror'}
        else if (categoryAbbr == 'fantasy') { categoryName = 'Fantasy'}
        else if (categoryAbbr == 'thriller') { categoryName = 'Thriller'}
        else if (categoryAbbr == 'histfic') { categoryName = 'Historical Fiction'}

        this.sortSelector.current.value = "date";

        this.setState({categoryName: categoryName, sortedStories: this.props.allStories.filter(story => story.allStoryInformation.tags.includes(categoryName)).sort((a, b) => { return b.timestamp - a.timestamp;}) });
    }

    componentDidMount() {
        this.setCategoryInfo();
    }

    componentDidUpdate() {
        if (this.state.categoryAbbr != (window.location.pathname).slice(10)) {
            this.setState({categoryAbbr: (window.location.pathname).slice(10), sortedStories: null});
            this.setCategoryInfo();
        }
    }

    render() {
        return (
            <Fragment>
                <BreadCrumb title="Business"/>
                <div className="archives padding-top-50">
                    <div className="container">
                        <div className="row row-eq-height">
                            <div className="col-lg-9">
                                <div className="businerss_news">
                                    <div className="row">
                                        <div className="col-12 align-self-center no-padding">
                                            <div className="category-title-container">
                                                {this.state.categoryName == 'Science Fiction' && (<img src={sciFiBannerBig} alt="category-title" />)}
                                                {this.state.categoryName == 'Supernatural' && (<img src={supernaturalBannerBig} alt="category-title" />)}
                                                {this.state.categoryName == 'Fantasy' && (<img src={fantasyBigBanner} alt="category-title" />)}
                                                {this.state.categoryName == 'Horror' && (<img src={horrorBannerBig} alt="category-title" />)}
                                                {this.state.categoryName == 'Thriller' && (<img src={thrillerBannerBig} alt="category-title" />)}
                                                {this.state.categoryName == 'Historical Fiction' && (<img src={histFicBigImage} alt="category-title" />)}
                                                <div className="category-title"><h2>{this.state.categoryName}</h2></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sort-by-row">
                                        <div className="sort-by-container">
                                            <div className="sort-by">Sort By:</div>
                                            <select className="sorting-dropdown" name="options" id="sort-selector" onChange={this.sortClicked} ref={this.sortSelector}>
                                                <option value="date">Date Added</option>
                                                <option value="poplate">Popular Lately</option>
                                                <option value="mostreads">Most Reads</option>
                                                <option value="mostreact">Most Reactions</option>
                                                <option value="longest">Longest</option>
                                                <option value="shortest">Shortest</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {this.state.sortedStories != null && this.state.sortedStories.map((story, index) => {
                                                
                                            let userInfo = this.props.allUsers.find(o => o.uid === story.creatorID);

                                            if (story.hidden == null && story.allStoryInformation.competitionId == null) {
                                                return (
                                                    <StoryPreview story={story} userInfo={userInfo} colWidth={4}/>
                                                )
                                            }
                                        })}
                                    </div>
                                    <PromptUserToWrite/>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <SideFeedContainer/>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        allStories: state.primary.allStories,
        allUsers: state.primary.allUsers
    }
}

export default connect (mapStateToProps) (CategoryPage)