import React, {Fragment} from 'react';
import BreadCrumb from "../components/BreadCrumb";
import {Link} from "react-router-dom";
import NewsLetter from "../components/NewsLetter";
import InstagramLink from './InstagramLink';

import CategoriesWidget from '../components/CategoriesWidget';

import './CategoryPages.css';

import '../components/primary-styles.css';

import theKeepImage from '../new_images/the_keep.jpg';
import theTimeMachineImage from '../new_images/time_machine.jpg';
import invisibleManImage from '../new_images/invisible_man.jpg';
import theTombImage from '../new_images/the_tomb.jpg';
import unsolicitedImage from '../new_images/unsolicited.jpg';

import FillOutSurvey from '../components/FillOutSurvey';

export default class ReviewsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {        
        };
    }

    componentDidMount() {
        this.props.firebase.analytics().logEvent('page_view', { page_name: window.location.pathname });
    }

    render() {
        return (
            <Fragment>
                <BreadCrumb title="Business"/>
                <div className="archives padding-top-50">
                    <div className="container">
                        <h1>Reviews</h1>
                        <div className="straight-line"></div>
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="businerss_news">
                                    <div className="row">
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="single_post post_type6 post_type7">
                                                <Link to="/reviews/the-keep"><img src={theKeepImage} alt="book image" className="review-book-image"/></Link>
                                                <div className="single_post_text">
                                                    <div className="meta5"><Link to="/">Supernatural</Link>
                                                        <Link to="/">December 3, 2020</Link>
                                                    </div>
                                                    <h4>
                                                        <Link to="/reviews/the-keep">The Keep - F. Paul Wilson</Link>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="single_post post_type6 post_type7">
                                                <Link to="/reviews/the-tomb"><img src={theTombImage} alt="book image" className="review-book-image"/></Link>
                                                <div className="single_post_text">
                                                    <div className="meta5"><Link to="/">Supernatural</Link>
                                                        <Link to="/">December 3, 2020</Link>
                                                    </div>
                                                    <h4>
                                                        <Link to="/reviews/the-tomb">The Tomb - F. Paul Wilson</Link>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6 pad-bottom-30">
                                            <div className="single_post post_type6 post_type7">
                                                <Link to="/reviews/the-invisible-man"><img src={invisibleManImage} alt="thumb" className="review-book-image"/></Link>
                                                <div className="single_post_text">
                                                    <div className="meta5"><Link to="/">Science Fiction</Link>
                                                        <Link to="/">December 3, 2020</Link>
                                                    </div>
                                                    <h4>
                                                        <Link to="/reviews/the-invisible-man">The Invisible Man - H.G. Wells</Link>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="single_post post_type6 post_type7">
                                                <Link to="/reviews/the-time-machine"><img src={theTimeMachineImage} alt="book image" className="review-book-image"/></Link>
                                                <div className="single_post_text">
                                                    <div className="meta5"><Link to="/">Science Fiction</Link>
                                                        <Link to="/">December 3, 2020</Link>
                                                    </div>
                                                    <h4>
                                                        <Link to="/reviews/the-time-machine">The Time Machine - H.G. Wells</Link>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="single_post post_type6 post_type7">
                                                <Link to="/reviews/unsolicited"><img src={unsolicitedImage} alt="book image" className="review-book-image"/></Link>
                                                <div className="single_post_text">
                                                    <div className="meta5"><Link to="/">Science Fiction</Link>
                                                        <Link to="/">December 3, 2020</Link>
                                                    </div>
                                                    <h4>
                                                        <Link to="/reviews/unsolicited">Unsolicited - Julie Kaewert</Link>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <CategoriesWidget/>
                                <NewsLetter firebase={this.props.firebase}/>
                                <InstagramLink/>
                                <FillOutSurvey/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="space-70"/>
            </Fragment>
        );
    }
};