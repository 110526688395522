import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import darkUserIcon from '../../new_images/icons/dark-user-icon.png';
import GenericErrorModal from '../GenericErrorModal';

const MessageUserModal = (props) => {

    const [matchingUsers, setMatchingUsers] = useState(props.allUsers);
    const [showGenericModal, setShowGenericModal] = useState(false);

    const changedSearchCriteria = (event) => {
        const query = event.target.value.toLowerCase();
        if (!(query === "")) {
            let filteredItems = props.allUsers.filter((user) => {
                return user.username.toLowerCase().indexOf(query) > -1 || user.fullname.toLowerCase().indexOf(query) > -1;
            })
            setMatchingUsers(filteredItems);
        }
    }

    const clickedUser = (userInfo) => {
        if (props.allMessages.filter(o => o.userId == userInfo.uid).length == 0) {
            props.clickedUser(userInfo);
        } else {
            setShowGenericModal(true);
        }
    }

    return (
        <Modal show={props.showModal} onHide={props.closeModal} className="likes-modal">
                <Modal.Header closeButton>
                    <div className="new-message-header">
                        <center>
                            <b>
                                Message User:
                            </b>
                        </center>

                        <input type="text" onChange={changedSearchCriteria} placeholder="Search user..." id="search-bar"/>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div clasName="liked-profiles-list">
                        {matchingUsers.map((userInfo, i) => {
                            return (
                                <>
                                    <div className="liked-profile pointer" onClick={() => clickedUser(userInfo)}>
                                        {userInfo.imgUrl != null && (
                                                <img src={userInfo.imgUrl} alt="User image" className="story-page-author-image"/>
                                        )}

                                        {userInfo.imgUrl == null && (
                                            <img src={darkUserIcon} alt="User image" className="story-page-author-image"/>
                                        )}

                                        <div className="liked-profile-info">
                                            <div className="flex-row">
                                                <div className="liked-profile-names">
                                                    {userInfo.fullname != "" && (
                                                        <div className="liked-profile-fullname">{userInfo.fullname}</div>
                                                    )}

                                                    <div className="liked-profile-username">@{userInfo.username}</div>
                                                </div>
                                            </div>

                                            <div className="liked-profile-description">
                                                {userInfo.description.length != 0 && (
                                                    <>{userInfo.description}</>
                                                )}
                                                {userInfo.description.length == 0 && (
                                                    <>No description to display!</>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="weak-line"/>
                                </>
                            )
                        })}
                    </div>
                </Modal.Body>
                <GenericErrorModal showModal={showGenericModal} closeModal={() => {setShowGenericModal(false)}}/>
        </Modal>
    )
}

export default MessageUserModal;