import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import './Publications.css';

function PublicationCard(props) {
    return (
        <div className={`col-sm-6 col-md-4 story-col`}>
            <Link className="publication-card-container" to={`publications/${props.path}`}>
                <img src={props.img} alt={"Publication image for " + props.title} className="publication-image" />
                <div className="publication-title">{props.title}</div>
            </Link>
        </div>
    )
}

export default PublicationCard;