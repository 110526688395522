import React, {Fragment} from 'react';
import { useEffect, useState } from 'react';
import BreadCrumb from "../../components/BreadCrumb";
import Button from "react-bootstrap/Button";
import { Spinner } from 'react-bootstrap';
import {Link} from "react-router-dom";
import '../CategoryPages.css';
import '../../components/primary-styles.css';
import StoryPreview from '../StoryPreview';
import SideFeedContainer from '../SideFeedContainer';
import { useSelector, useDispatch } from 'react-redux';
import { css } from '@emotion/css';

const SingleCompetition = (props) => {

    const [competition, setcompetition] = useState(null);

    const allCompetitions = useSelector(state => state.primary.allCompetitions);
    const currentUser = useSelector(state => state.primary.currentUser);
    const allUsers = useSelector(state => state.primary.allUsers);
    const allStories = useSelector(state => state.primary.allStories);

    const dispatch = useDispatch();

    // const closeModalFromWrite = () => {
    //     setShowModal(false);
    //     if (loginWasSuccess) {
    //         setTimeout(() => {
    //             this.props.history.push('/write');
    //         }, 300)
    //         setLoginWasSuccess(false);
    //     }
    // }

    // const wasSuccess = () => {
    //     setLoginWasSuccess(true);
    // }


    useEffect(() => {
        let storycompetition = allCompetitions.find(element => element.id == (window.location.pathname).slice(14));
        setcompetition(storycompetition);
    })

    return (
        <Fragment>
                <BreadCrumb title="Business"/>
                <div className="archives padding-top-50">
                    <div className="container">
                        {competition != null && (
                            <div className="row row-eq-height">
                                <div className="col-lg-9">
                                    <div className="businerss_news">
                                        {/* <div className="row">
                                            <div className="col-12 align-self-center no-padding">
                                                <div className="category-title-container">
                                                    <img src={histFicBigImage} alt="category-title" />
                                                    <div className="category-title"><h2>{competition.title}</h2></div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className='competition-header'>
                                            <h2>{competition.title}</h2>
                                            <div className='competition-description'>{competition.storyIds.length} Submission(s) - Created {new Date(competition.timestamp).toLocaleDateString("en-US")}</div>
                                        </div>

                                        <div className={css`background-color: #233340; margin-top: 10px; margin-bottom: 10px; border-radius: .3rem;`}>
                                            <div className={css`padding: 10px;`}>
                                                <div className={css`display: flex; flex-direction: row;`}><b className={css`padding-top: 3px;`}>Status:</b> 
                                                    {competition.status === "live" && (
                                                        <div className={css`display: flex; flex-direction: row; align-items: center; padding-left: 5px;`}>
                                                                <Spinner animation="grow" variant="success" size="sm"/>
                                                                <div className={css`color: #28a745; padding-top: 3px;`}>LIVE</div>
                                                        </div>
                                                    )}
                                                    {competition.status === "complete" && (
                                                        <div className={css`color: red; padding-top: 3px; padding-left: 3px;`}>CLOSED</div>
                                                    )}
                                                </div>
                                                <br/>
                                                <div>{competition.description}</div>
                                                <br/>
                                                <div><b>Prize:</b> {competition.prize}</div>
                                                <div><b>Due:</b> {new Date(competition.due).toLocaleDateString("en-US")}</div>
                                                <div>Read the full <Link to="/competition-terms">competition terms.</Link></div>
                                            </div>
                                        </div>

                                        {currentUser != null && competition.status === 'live' && (
                                            <Link to={`/write?competition=${competition.id}`}>
                                                <Button variant="primary" size="lg" className="add-story-button">Post Story</Button>
                                            </Link>
                                        )}
                                        {currentUser == null && competition.status === 'live' && (
                                            <Button variant="primary" size="lg" className="add-story-button" onClick={() => {dispatch({type: "SET_LOGIN_MODALS", payload: {showLoginModal: true,showCreateAccount: true,showSuccess: false,showPassReset: false}});}}>Add Story</Button>
                                        )}

                                        {(competition.status === 'live' || competition.status === 'judging') && (
                                            <>
                                                <h3>Stories</h3>
                                                <i>Stories will be visible once the competition is complete.</i>
                                            </>
                                        )}

                                        {competition.status === 'complete' && (
                                            <div className="row">
                                                {competition.storyIds != null && competition.storyIds.length != 0 && competition.storyIds.map((storyId, index) => {
                                                        
                                                    let storyInfo = allStories.find(o => o.id === storyId);

                                                    if(storyInfo != null) {
                                                        let userInfo = allUsers.find(o => o.uid === storyInfo.creatorID);

                                                        if (storyInfo.hidden == null && competition.winnerId == storyId) {
                                                            return (
                                                                <StoryPreview story={storyInfo} userInfo={userInfo} colWidth={4} winner={true}/>
                                                            )
                                                        }
                                                    } 
                                                    // else {
                                                    //     let newStoryIds = allcompetitions.find(element => element.id == competition.id).storyIds;
                                                    //     delete newStoryIds[storyId];
                                                    //     let db = props.firebase.firestore();
                                                    //     db.collection("competitions").doc(competition.id).update({
                                                    //         storyIds: newStoryIds
                                                    //     })
                                                    // }
                                                })}

                                                {/* {competition.storyIds != null && competition.storyIds.length != 0 && competition.storyIds.map((storyId, index) => {
                                                        
                                                        let storyInfo = allStories.find(o => o.id === storyId);
    
                                                        if(storyInfo != null) {
                                                            let userInfo = allUsers.find(o => o.uid === storyInfo.creatorID);
    
                                                            if (storyInfo.hidden == null && (competition.hon1Id == storyId || competition.hon2Id == storyId) && window.screen.width > 414) {
                                                                return (
                                                                    <StoryPreview story={storyInfo} userInfo={userInfo} colWidth={4} honMen={true}/>
                                                                )
                                                            }
                                                        } 
                                                        // else {
                                                        //     let newStoryIds = allcompetitions.find(element => element.id == competition.id).storyIds;
                                                        //     delete newStoryIds[storyId];
                                                        //     let db = props.firebase.firestore();
                                                        //     db.collection("competitions").doc(competition.id).update({
                                                        //         storyIds: newStoryIds
                                                        //     })
                                                        // }
                                                    })} */}
                                            </div>
                                        )}

                                        

                                        {currentUser?.isAdmin && (
                                            <div>
                                                <br/><br/><h4>ADMIN VIEW: </h4><br/>
                                                <div className="row">
                                                    {competition.storyIds != null && competition.storyIds.length != 0 && competition.storyIds.map((storyId, index) => {
                                                            
                                                        let storyInfo = allStories.find(o => o.id === storyId);

                                                        if(storyInfo != null) {
                                                            let userInfo = allUsers.find(o => o.uid === storyInfo.creatorID);

                                                            if (storyInfo.hidden == null) {
                                                                return (
                                                                    <StoryPreview story={storyInfo} userInfo={userInfo} colWidth={4}/>
                                                                )
                                                            }
                                                        } 
                                                        // else {
                                                        //     let newStoryIds = allcompetitions.find(element => element.id == competition.id).storyIds;
                                                        //     delete newStoryIds[storyId];
                                                        //     let db = props.firebase.firestore();
                                                        //     db.collection("competitions").doc(competition.id).update({
                                                        //         storyIds: newStoryIds
                                                        //     })
                                                        // }
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                        
                                        
                                        {/* {competition.storyIds == null || competition.storyIds.length == 0 && (
                                                <b>No stories added yet! Click the button to add a story to this competition! </b>
                                        )} */}
                                        {/* <competitionUserToWrite reloadAllStories={props.reloadAllStories} currentUser={props.currentUser} allUsers={props.allUsers} firebase={props.firebase} history={props.history}/> */}
                                    </div>
                                    <div className={css`padding-bottom: 20px;`}></div>
                                </div>
                                <div className="col-lg-3">
                                    <SideFeedContainer showAds={true} showCategories={false}/>
                                </div>
                            </div>
                        )}
                        
                    </div>
                </div>
            </Fragment>

    )
}

export default SingleCompetition;