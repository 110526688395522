import React, {Fragment, useState, useEffect} from 'react';
import Modal from "react-bootstrap/Modal";

import './CreateAccountModal.css';
import CreateAccountModal from './CreateAccountModal';
import LoginModal from './LoginModal';
import AccountCreatedSuccess from './AccountCreatedSuccess';
import PasswordResetModal from './PasswordResetModal';
import { logPageView } from '../FirebaseActions';

import { useDispatch, useSelector } from 'react-redux';

const AccountModalContainer = (props) => {
    const dispatch = useDispatch();
    const showModal = useSelector(state => state.primary.loginModals.showLoginModal);
    const showCreateAccount = useSelector(state => state.primary.loginModals.showCreateAccount);
    const showSuccess = useSelector(state => state.primary.loginModals.showSuccess);
    const showPassReset = useSelector(state => state.primary.loginModals.showPassReset);
    const newusername = useSelector(state => state.primary.loginModals.newUsername);

    const forgotPass = () => dispatch({type: "SET_LOGIN_MODALS", payload: {showLoginModal: true,showCreateAccount: false,showSuccess: false,showPassReset: true}})
    const showCreate = () => dispatch({type: "SET_LOGIN_MODALS", payload: {showLoginModal: true,showCreateAccount: true,showSuccess: false,showPassReset: false}})
    const showLogin = () => dispatch({type: "SET_LOGIN_MODALS", payload: {showLoginModal: true,showCreateAccount: false,showSuccess: false,showPassReset: false}});
    const closeModal = () => dispatch({type: "SET_LOGIN_MODALS", payload: {showLoginModal: false,showCreateAccount: true,showSuccess: false,showPassReset: false}});

    useEffect(() => {
        logPageView('account creation modal');
    }, [])

    return ( 
        <Modal show={showModal} onHide={() => dispatch({type: "SET_LOGIN_MODALS", payload: {showLoginModal: false,showCreateAccount: true,showSuccess: false,showPassReset: false}})} size="lg">
            {showCreateAccount && !showSuccess && !showPassReset && (
                <CreateAccountModal showLogin={showLogin}/>
            )}
            {!showCreateAccount && showSuccess && !showPassReset && (
                <AccountCreatedSuccess newusername={newusername} closeModal={closeModal}/>
            )}
            {!showCreateAccount && !showSuccess && !showPassReset && (
                <LoginModal forgotPass={forgotPass} showCreate={showCreate} closeModal={closeModal}/>
            )}
            {!showCreateAccount && !showSuccess && showPassReset && (
                <PasswordResetModal showLogin={showLogin} closeModal={closeModal}/>
            )}
        </Modal>
    )

}

export default AccountModalContainer;